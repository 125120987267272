import {IVisibilityChange} from '../../../services/ts-visibility-change/ts-visibility-change.service';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {ROOM_MODES, STATUS_MESSAGES} from '../../../states/meeting/meeting.settings';
import get from 'lodash/get';
import {MediaServiceType, SessionClientRole, VideoTypesEnum} from '@techsee/techsee-media-service/lib/MediaConstants';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {MeetingMode, UserType} from '@techsee/techsee-common/lib/constants/room.constants';
import {getMeetingTracer} from '../../../states/meeting/meeting.tracer';
import {ITsNetworkInfo} from '../../../services/ts-network-info/ts-network-info.service';
import {ITsTranslationHelper} from '../../../services/ts-translation-helper/ts-translation-helper.service';
import {GEOLOCATION_DENIED_CODE} from '../../../states/meeting/meeting.controller';
import includes from 'lodash/includes';
import {
    stateByMode,
    MeetingStateDefinition,
    MeetingState
} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import assign from 'lodash/assign';
import {ITsBrowserDetect} from '../../../services/ts-browser-detect/ts-browser-detect.service';
import {IRoomInfo, IUser} from './interfaces/AngularInterfaces';
import {FeatureService, IFeatureService} from '@techsee/techsee-client-infra/lib/services/FeatureService';
import {EndMeetingController, IEndMeetingController} from './components/modal/end-meeting/EndMeetingController';
import {IVideoController, VideoController} from './components/video/VideoController';
import {
    ITermsAndConditionsController,
    TermsAndConditionsController
} from './components/modal/terms-and-conditions/Controller';
import {FacemeetController} from './facemeet.controller';
import {ILocalizationService} from '../../services/LocalizationService';
import {
    AudioState,
    createMultipartyController,
    MultipartyEvents,
    MultipartySessionSettings,
    MultipartyStreamDestroyedEvent
} from '@techsee/techsee-media-service-client';
import {DetectWebRtcService} from '@techsee/techsee-client-infra/lib/services/DetectWebRtcService';
import {ITsEnvironmentDetect, TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {VideoFilterType} from '@techsee/techsee-common/lib/constants/account.constants';
import {action, observable} from 'mobx';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

const trace = getMeetingTracer('MeetingController');

interface IMessageAlertWithCallbck {
    label: string;
    color: string;
    display: boolean;
    callbackMessage: string;
    callback: () => void;
}

export interface IMeetingController {
    endMeetingController?: IEndMeetingController;
    videoController?: IVideoController;
    termsAndConditionsController?: ITermsAndConditionsController;
    messageAlertWithCallback: IMessageAlertWithCallbck;
}

export class MeetingController implements IMeetingController {
    public videoController?: VideoController;

    public endMeetingController?: EndMeetingController;

    public termsAndConditionsController?: TermsAndConditionsController;

    @observable
    public messageAlertWithCallback: IMessageAlertWithCallbck;

    private facemeetController?: FacemeetController;
    private multipartyControllerInitializationInProgress = false;
    private _visibilityChange: IVisibilityChange;
    private _tsChatApi: any;
    private _db: any;
    private $localStorage: any;
    private _roomInfo: IRoomInfo;
    private roomId: string;
    private roomCode: string;
    private intent: string;
    private referrer: string | undefined;
    private usingApplication: boolean;
    private _currentUser: IUser;
    private _tsEventService: any;
    private _browserUtilsService: IBrowserUtilsService;
    private _networkInfoService: ITsNetworkInfo;
    private _translationHelper: ITsTranslationHelper;
    private _stateHelper: any;
    private isReadyForSync: boolean;
    private gotSynced = false;
    private endParams: {csi?: string};
    private featureService: IFeatureService;
    private _playAudio: boolean;
    private _isDesktop: boolean;
    private isAgentInControl = false;

    // eslint-disable-next-line max-params
    constructor(
        private readonly localizationService: ILocalizationService,
        private readonly angularLocalization: any,
        visibilityChange: IVisibilityChange,
        tsChatApi: any,
        db: any,
        $localStorage: any,
        roomInfo: IRoomInfo,
        currentUser: IUser,
        tsEventService: any,
        browserUtilsService: IBrowserUtilsService,
        networkInfoService: ITsNetworkInfo,
        translationHelper: ITsTranslationHelper,
        tsStateHelper: any,
        private readonly tsBrowserDetect: ITsBrowserDetect,
        private readonly tsWebRtcDetector: DetectWebRtcService,
        endParams: {csi?: string},
        theme: string | undefined,
        private readonly environmentService: ITsEnvironmentDetect
    ) {
        this._visibilityChange = visibilityChange;
        this._tsChatApi = tsChatApi;
        this._db = db;
        this.$localStorage = $localStorage;
        this._currentUser = currentUser;
        this._roomInfo = roomInfo;
        this.roomId = roomInfo._id || '';
        this.roomCode = roomInfo.roomCode || '';
        this.intent = roomInfo.intent || '';
        this.referrer = roomInfo.referrer;
        this._playAudio = roomInfo.audio === 'yes';
        this.usingApplication = !!roomInfo.usingApplication;
        this._tsEventService = tsEventService;
        this._browserUtilsService = browserUtilsService;
        this._networkInfoService = networkInfoService;
        this._translationHelper = translationHelper;
        this._stateHelper = tsStateHelper;
        this.endParams = endParams;
        this._isDesktop = environmentService.isDesktop();
        this.messageAlertWithCallback = {
            label: '',
            color: '#EF4F00',
            display: false,
            callbackMessage: '',
            callback: this.stopRemoteControl
        };

        this.featureService = new FeatureService(environmentService as TsEnvironmentDetect);

        if (this.roomId) {
            this._db.Rooms.createInstance({_id: this.roomId}).setDeviceInfo(
                this._tsChatApi.browserInfo,
                false,
                !!this.roomCode,
                this._roomInfo.mediaServiceType === MediaServiceType.OPENTOK && this._isDesktop
                    ? PlatformType.desktop_web
                    : PlatformType.mobile_web,
                this.usingApplication
            );
        }

        this.sendMeetingEventLog(STATUS_MESSAGES.TECHSEE_MOBILE_LOADED);
        this._db.Rooms.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'techseeMobileLoaded',
                    value: true
                }
            }
        });
        this._stateHelper.enable();
        this.isReadyForSync = false;
    }

    init(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.tsBrowserDetect.run(
                (err: any, chromeRedirectionFailed: boolean) => {
                    if (err) {
                        this.reportDeviceUnsupported();

                        return this.goToUnsupportedPage(false);
                    }

                    if (chromeRedirectionFailed && (this.roomId || this.roomCode)) {
                        this.goToFinishMeetingPageIfConnected();
                    } else if (this.roomId || this.roomCode) {
                        return (
                            this.initializeMeeting()
                                .then(resolve)
                                // @ts-ignore
                                .finally(() => this.startMeeting())
                        );
                    } else {
                        this._stateHelper.safeGo('start.main', {postMeeting: true}, {reload: true});
                    }
                },
                (next: (...args: any) => void) => {
                    this.sendMeetingEventLog(STATUS_MESSAGES.REDIRECTING_TO_CHROME)
                        .catch(() => null)
                        .then(() => next());
                },
                this.referrer,
                this.intent === 'no'
            );
        });
    }

    private reportDeviceUnsupported(): void {
        this.sendMeetingEventLog(STATUS_MESSAGES.CLIENT_CONNECTED_TO_SOCKET, {
            calledFrom: 'Initial client sync | meeting ts'
        });
        this._tsChatApi.sendLog(STATUS_MESSAGES.UNSUPPORTED_DEVICE);

        this.sendMeetingEventLog(STATUS_MESSAGES.UNSUPPORTED_DEVICE, {reason: 'device unsupported'});

        this._db.Rooms.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'supportedDevice',
                    value: false
                }
            }
        });
    }

    private goToUnsupportedPage(isUnsupportedBrowser: boolean): void {
        this._stateHelper.safeGo('unsupported', {isUnsupportedBrowser: isUnsupportedBrowser});
    }

    private async goToFinishMeetingPageIfConnected(): Promise<void> {
        try {
            const reply = await this._db.Rooms.clientConnected({
                params: {
                    roomId: this.roomId,
                    roomCode: this.roomCode
                }
            });

            const isClientConnected = reply.data;

            if (isClientConnected) {
                return this.goToFinishMeetingPage();
            }
        } catch {
            this.goToFinishMeetingPage();
        }
    }

    private async sendMeetingEventLog(type: string, meta?: any): Promise<void> {
        return this._tsEventService.sendEventLog(get(this._currentUser, '_id'), this.roomId, type, meta);
    }

    private async initializeMeeting(): Promise<void> {
        await this.joinMeeting();
        await this.syncLanguage();
        this.messageAlertWithCallback.label =
            this.localizationService.translate('FACE_MEET.REMOTE_CONTROL.AGENT_IS_CONTROLLING') + ' ';
        this.messageAlertWithCallback.callbackMessage = this.localizationService.translate(
            'FACE_MEET.REMOTE_CONTROL.STOP_REMOTE_CONTROL'
        );

        this.initInternal();
    }

    private joinMeeting = async (): Promise<void> => {
        const clientWebRtcInfo = await this.tsWebRtcDetector.getWebRtcInfo();

        this._tsChatApi.setWebRTCSupport(clientWebRtcInfo);
        this.sendMeetingEventLog(STATUS_MESSAGES.WEBRTC_SUPPORTED, clientWebRtcInfo);

        this._tsChatApi
            .connect(this.roomId, UserType.client, this.roomCode, PlatformType.mobile_web)
            .catch(this.goToFinishMeetingPage);

        await new Promise((resolve) => this._tsChatApi.once(socketEvents.CLIENT_IN.SYNC, resolve));
    };

    async syncLanguage(): Promise<void> {
        const currentLanguage =
            this._tsChatApi.accountSettings.clientLanguage || this._tsChatApi.accountSettings.language;
        const previousLanguage = this._browserUtilsService.getFromLocalStorage('techseeClientLang');
        const accountId = this._tsChatApi.accountSettings.accountId;

        if (previousLanguage !== currentLanguage) {
            this._browserUtilsService.saveToLocalStorage('techseeClientLang', currentLanguage);
            this.$localStorage.techseeClientLang = currentLanguage;
        }

        // Line below was added to make sure it will load language as "fallbackLanguage" doesn't do it:
        this.angularLocalization.use(currentLanguage);
        this.angularLocalization.fallbackLanguage(currentLanguage);

        return this._translationHelper
            .storeAccountCustomStrings(accountId)
            .then(() => this.updateLanguage(currentLanguage, accountId));
    }

    private async updateLanguage(lang: string, accountId: string): Promise<void> {
        // Use account-specific language:
        this.angularLocalization.use(accountId + lang);

        this.localizationService.setAccountData(accountId, lang);

        return this.localizationService.init();
    }

    private initInternal(): void {
        this.endMeetingController = new EndMeetingController(this.finishMeeting, this.localizationService.translate);
        this.facemeetController = new FacemeetController(
            this._tsChatApi,
            this._db,
            this.roomId,
            this._currentUser,
            this._tsEventService
        );
        this.videoController = new VideoController(
            this._db,
            this.roomId,
            this._tsChatApi,
            this.localizationService.translate,
            this.sendMeetingEventLog.bind(this),
            this.facemeetController.faceMeetMediaPermission,
            this.endMeetingController?.showEndMeeting,
            this.environmentService
        );
        this.termsAndConditionsController = new TermsAndConditionsController(
            this.localizationService.translate,
            this._tsChatApi,
            this._db,
            this._tsEventService,
            this._browserUtilsService
        );

        const portrait = window?.matchMedia('(orientation: portrait)');

        if (portrait) {
            this.videoController?.setIsPortrait(portrait?.matches);

            portrait.addEventListener('change', (e) => {
                this.videoController?.setIsPortrait(e?.matches);
            });
        }
    }

    private async startMeeting(): Promise<void> {
        this.sendMeetingEventLog(STATUS_MESSAGES.CLIENT_CONNECTED_TO_SOCKET, {
            calledFrom: 'Initial client sync | meeting ts'
        });

        this._tsEventService.setVerboseSettings(
            get(this._tsChatApi, 'accountSettings.verboseLogging'),
            get(this._currentUser, '_id') || 'none',
            get(this._tsChatApi, 'accountSettings.accountId'),
            this.roomId,
            this.roomCode
        );
        this._tsChatApi.setRoomNetworkInfo(
            this._networkInfoService.connectionType,
            this._networkInfoService.downlinkMax,
            this.roomId
        );

        this.initMeetingHandlers();

        try {
            await this.requireAcceptingTOS();

            this.isReadyForSync = true;
            await this.syncMeeting();

            const clientSupportsWebRtc = this._tsChatApi.WebRTCSupport.isWebRTCSupported;

            this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.WEBRTC_SUPPORTED, clientSupportsWebRtc);

            if (!clientSupportsWebRtc) {
                this.goToFinishMeetingPage();
            }
        } catch (error) {
            this.isReadyForSync = true;

            this.sendMeetingEventLog(STATUS_MESSAGES.SYNC_ERROR, {
                side: PlatformType.mobile_web,
                error: error && typeof error === 'string' ? error.toString() : JSON.stringify(error)
            });
        }
    }

    private initMeetingHandlers(): void {
        this._tsChatApi.on(socketEvents.CLIENT_IN.SYNC, this.syncMeeting.bind(this));
        this._tsChatApi.on(socketEvents.CLIENT_IN.SOCKET_DISCONNECTED, this.goToFinishMeetingPage);
        this._tsChatApi.on(socketEvents.CLIENT_IN_CHAT_API.END_MEETING_ACTION, this.goToFinishMeetingPage);
        this._tsChatApi.on(socketEvents.CLIENT_IN.CONNECTION_STATUS_CHANGED, this.syncMeeting.bind(this));
        this._tsChatApi.on(socketEvents.CLIENT_IN.ROOM_REJECTED, this.goToFinishMeetingPage);
        this._tsChatApi.on(socketEvents.CLIENT_IN.FORCE_TIMEOUT, this.goToFinishMeetingPage);
        this._tsChatApi.on(socketEvents.CLIENT_IN.JOIN_ROOM, this.updateRoomId.bind(this));
        this._tsChatApi.on(socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_OPENTOK, this.syncMediaSession.bind(this));
        this._tsChatApi.on(
            socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_FACEMEET_BIG_VIDEO_PINNED_USER,
            this.setPinnedVideo.bind(this)
        );
        this._tsChatApi.on(socketEvents.CLIENT_IN.LOG, (log: {name: string}) => {
            if (log.name === 'MEDIA_PERMISSION_ALLOW') {
                this.syncLocation();
            }
        });
        // FIXME: Temporary hack to avoid breakage of video connectivity in FACE_MEET and Vonage
        this._tsChatApi.on(socketEvents.CLIENT_IN.CONNECTION_STATUS_CHANGED, (isConnected: any) => {
            if (isConnected) {
                // @ts-ignore
                window.location.reload();
            }
        });
    }

    private async requireAcceptingTOS(): Promise<void> {
        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_ON_SCREEN_SHARE_TURN_OFF_GUIDANCE_IOS, false);
        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.SCREEN_SHARE_CAPTURING_APPROVED_IOS, false);

        const isApproved: boolean = await this.termsAndConditionsController?.init(
            MeetingState.FaceMeet,
            this._currentUser.role
        );

        if (!isApproved) {
            this.finishMeeting();

            return;
        }

        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.FACEMEET_HANDSHAKE_SUCCESS, true);
    }

    private updateRoomId(roomId: string): void {
        this.roomId = roomId;
    }

    async syncMeeting(): Promise<void> {
        this.updateFeatureService();

        if (!this._tsChatApi.synced || !this.isReadyForSync) {
            trace.info(`syncMeeting not ready yet.
            received sync event from chatapi: ${this._tsChatApi.synced}. isReadyForSync: ${this.isReadyForSync}`);

            return Promise.resolve();
        }

        trace.info('syncMeeting');

        if (!this.gotSynced) {
            this.sendMeetingEventLog(STATUS_MESSAGES.SYNC);
            this.gotSynced = true;
        }

        const isMeetingActive =
            this._tsChatApi.dashboard.meeting &&
            (!this._tsChatApi.client.tosRejected ||
                (this._tsChatApi.client.tosRejected && this._tsChatApi.client.isReviewingTOS));

        if (!isMeetingActive) {
            return this._tsChatApi
                .disconnect(true)
                .catch((error: any) => {
                    this._tsChatApi.sendLog(error);
                })
                .then(this.goToFinishMeetingPage);
        }

        this.syncUnderControlMessageAlert();

        return Promise.resolve()
            .then(() => {
                this.syncMediaSession().then(() => Promise.resolve());
            })
            .then(() => {
                this.syncVisibility();

                return Promise.resolve();
            })
            .catch((error) => {
                trace.error('SyncMeetingError', error);
                this.sendMeetingEventLog(STATUS_MESSAGES.SYNC_ERROR, {
                    side: PlatformType.mobile_web,
                    error: error && typeof error === 'string' ? error.toString() : JSON.stringify(error)
                });
            });
    }

    @action
    syncUnderControlMessageAlert() {
        const isInControl = get(this._tsChatApi, 'client.agentHasRemoteControl');

        if (this.isAgentInControl !== isInControl) {
            this.isAgentInControl = isInControl;

            if (this.isAgentInControl) {
                this.messageAlertWithCallback.display = true;
            } else {
                this.messageAlertWithCallback.display = false;
            }
        }
    }

    private updateFeatureService(): void {
        if (this._tsChatApi.accountSettings) {
            this.featureService.setAccountSettings(this._tsChatApi.accountSettings);

            if (!this.featureService.isFaceMeetEnabled) {
                trace.error('FACE_MEET Setting is not enabled');
            }
        }
    }

    private syncVisibility(): void {
        const newState = this._visibilityChange.isVisible();
        const oldState = this._tsChatApi.client.visible;

        if (newState !== oldState) {
            // Needed since Chrome 56+ sends a visiblityChange event reporting
            // a hidden state, so on reloads we need to refresh the status
            this._tsChatApi.visibilityChanged(this._visibilityChange.isVisible());
        }
    }

    private get isVideoFilter(): Boolean {
        return (
            this._tsChatApi.accountSettings.virtualBackground.enabledInFaceMeet &&
            this._tsChatApi.accountSettings.virtualBackground.enabledBlurCustomer
        );
    }

    @action
    private syncMediaSession = async (): Promise<void> => {
        trace.info('syncMediaSession');

        const sessionIsNeeded = includes([ROOM_MODES.faceMeet], get(this._tsChatApi, 'client.mode'));

        if (!sessionIsNeeded || !this._tsChatApi.areBothSidesConnected || !get(this._tsChatApi, 'dashboard.opentok')) {
            return;
        }

        const currentState = stateByMode(get(this._tsChatApi, 'client.mode'), true);
        const isHandshakeSuccess = get(
            this._tsChatApi,
            'client.' + (currentState as MeetingStateDefinition).handshakeProp
        );

        if (!isHandshakeSuccess) {
            trace.info('Handshake for current state is not success yet. Skipping connect to session');

            return;
        }

        const mediaSessionCredentials = assign({}, get(this._tsChatApi, 'dashboard.opentok.session'));

        if (!mediaSessionCredentials || Object.getOwnPropertyNames(mediaSessionCredentials).length < 2) {
            trace.info(
                'Credentials for media session are not arrived yet. Skipping connect to session',
                mediaSessionCredentials
            );

            return;
        }
        const dashboardUA = get(this._tsChatApi, 'dashboard.browser.userAgent');
        const agentEnvironment = new TsEnvironmentDetect(dashboardUA, undefined, true);
        const isEnableDesktopSharing =
            (this._isDesktop && agentEnvironment.isDesktop()) ||
            get(this._tsChatApi, 'accountSettings.desktopSharingToMobile');
        const mediaServiceSettings: MultipartySessionSettings = {
            // when client or agent select None as default camera in admin,
            // flag startSessionWithVideo will be false and the default camera will become the Front camera
            cameraEnabled:
                this._tsChatApi.dashboard.initiateWithVideo === false
                    ? false
                    : this._tsChatApi.accountSettings.faceMeetClientStartSessionWithVideo,
            selectedCamera: this._tsChatApi.accountSettings.faceMeetCustomerDefaultCamera,
            mediaServiceType: this._roomInfo.mediaServiceType,
            audioState: this._playAudio ? AudioState.ENABLED : AudioState.DISABLED,
            enableDesktopSharing: this._tsChatApi.accountSettings.allowDesktopSharingInTwoWay && isEnableDesktopSharing,
            videoFilter: this.isVideoFilter ? VideoFilterType.BLUR : VideoFilterType.NONE,
            ipWhitelist: get(this._tsChatApi, 'accountSettings.useOnlyOpenTokAllowedIPS', false)
        };

        const isPeerMediaPermissionRejected =
            !this._tsChatApi.dashboard.mediaPermissionsAccepted && this._tsChatApi.dashboard.mediaPermissionsRejected;

        const {sessionId, token, apiKey} = mediaSessionCredentials;

        if (
            !this.multipartyControllerInitializationInProgress &&
            !this.videoController?.multipartyController &&
            sessionId &&
            token &&
            apiKey &&
            this.isReadyForSync
        ) {
            this.multipartyControllerInitializationInProgress = true;
            createMultipartyController({sessionId, token, apiKey}, mediaServiceSettings, SessionClientRole.USER).then(
                (controller: any) => {
                    controller.setPinnedVideo(this._tsChatApi.dashboard.facemeetBigVideoPinnedUser);
                    this.videoController?.setMultipartyController(controller);
                    this.multipartyControllerInitializationInProgress = false;
                    controller.on(MultipartyEvents.streamDestroyed, (event: MultipartyStreamDestroyedEvent) => {
                        const {videoType, clientRole} = event;

                        if (videoType === VideoTypesEnum.SCREEN) {
                            if (clientRole === SessionClientRole.USER) {
                                this.stopRemoteControl();
                                this._tsChatApi.setStatus(
                                    socketEvents.CLIENT_OUT_SET_STATUS.CLICK_ON_DESKTOP_SHARE,
                                    false
                                );
                            } else if (clientRole === SessionClientRole.AGENT) {
                                this.videoController?.isAgentClickOnScreenShare
                                    ? this.videoController?.setAgentClickOnScreenShare(false)
                                    : null;
                            }
                        }
                    });
                }
            );
        }
        this.videoController?.setIsPeerMediaPermissionsRejected(isPeerMediaPermissionRejected);
    };

    private setPinnedVideo() {
        this.videoController?.setPinnedVideo(this._tsChatApi.dashboard.facemeetBigVideoPinnedUser);
    }

    private syncLocation(): void {
        if (!this.featureService.mobileGeolocationEnabled || get(this._tsChatApi, 'client.location')) {
            return;
        }

        window.navigator.geolocation.getCurrentPosition(
            (pos) => {
                this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.LOCATION, {
                    lon: pos.coords.longitude,
                    lat: pos.coords.latitude,
                    altitude: pos.coords.altitude,
                    accuracy: pos.coords.accuracy
                });
                this._tsChatApi.sendLog(STATUS_MESSAGES.CLIENT_LOCATION);
            },
            (err) => {
                if (err.code === GEOLOCATION_DENIED_CODE) {
                    this._tsChatApi.sendLog(STATUS_MESSAGES.CLIENT_LOCATION_DENIED);
                } else {
                    this._tsChatApi.sendLog(STATUS_MESSAGES.CLIENT_LOCATION_ERROR);
                }
            }
        );
    }

    private finishMeeting = (): void => {
        try {
            this._tsChatApi.sendLog(STATUS_MESSAGES.CUSTOMER_ENDED_THE_MEETING);
            this._tsEventService.sendEventLog(
                'none',
                this._tsChatApi.roomId || 'none',
                STATUS_MESSAGES.CUSTOMER_ENDED_THE_MEETING
            );
            this._tsChatApi.disconnect();
        } finally {
            this._tsChatApi.unlock();
            this.goToFinishMeetingPage();
        }
    };

    private goToFinishMeetingPage = (): void => {
        this.videoController?.multipartyController?.disconnect();

        const endParam = {
            ...this.endParams,
            webRtcSupported: this._tsChatApi.WebRTCSupport.isWebRTCSupported
        };

        return this._stateHelper.safeGo('endNew', endParam);
    };

    private stopRemoteControl = () => {
        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.AGENT_HAS_REMOTE_CONTROL, false);

        this.sendMeetingEventLog(STATUS_MESSAGES.CLIENT_DESKTOP_SHARE_STOP_REMOTE_CONTROL_SENT, {
            description: 'Customer desktop sharing stop remote control request sent',
            origin: MeetingMode.faceMeet
        });
    };
}
