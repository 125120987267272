'use strict';

import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';

export interface ITsTranslationHelper {
    getAccountCustomStrings(): any;
    storeAccountCustomStrings(accountId: string): Promise<void>;
}

export class TsTranslationHelper {
    private db: any;
    private accountCustomStrings: any;

    //We use this type of injection, because ng-annotate-loader not runs on TypeScript files.
    static $inject = ['db'];

    constructor(db: any) {
        'ngInject';

        this.db = db;
        this.accountCustomStrings = {};
    }

    /**
     * gets and stores custom translations for an account
     * @param accountId
     */
    storeAccountCustomStrings(accountId: string) {
        if (!accountId) {
            return Promise.resolve({});
        }

        return (
            this.db.CustomStrings.byAccount({params: {accountId, platformType: PlatformType.mobile_web}})
                // eslint-disable-next-line no-return-assign
                .then((result: any) => (this.accountCustomStrings = result.data))
        );
    }

    getAccountCustomStrings() {
        return this.accountCustomStrings;
    }
}
