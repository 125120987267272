// eslint-disable-next-line no-use-before-define
import React from 'react';
import {TechseeBaseComponent} from '../../_shared/techsee-base-component';
import {IDesignable} from '../../_shared/reusable-props';

import './styles.scss';
import './icons-special.scss';

export enum IconColors {
    Default = 'default',
    Error = 'error',
    White = 'white',
    Blue = 'blue',
    DarkBlue = 'darkblue',
    KashmirBlue = 'kashmirblue',
    DarkGray = 'dark-gray',
    Green = 'green',
    Yellow = 'yellow',
    Grey = 'grey',
    LightGray = 'lightgray',
    LightBlack = 'lightblack',
    Red = 'red',
    Black = 'black'
}

export enum BorderSizeOption {
    THIN = 'thin',
    THICK = 'thick'
}

export enum IconSizes {
    XXLarge = 'xxlarge',
    XLarge = 'xlarge',
    Large = 'large',
    LargeObsolete = 'large-obsolete',
    Medium = 'medium',
    Small = 'small',
    XSmall = 'xsmall',
    MobileLarge = 'mobile-large',
    MobileLargeObsolete = 'mobile-large-obsolete'
}

export enum IconAnimations {
    None = 'none',
    Rotate = 'rotate'
}

export enum BorderShapes {
    Circle = 'cirle',
    Square = 'square'
}

export enum BackgroundTransparencies {
    FULL = 0,
    TEN = 10,
    HALF = 50,
    NONE = 100
}

export enum IconTransparencies {
    FULL = 0,
    HALF = 50,
    NONE = 100
}

export type IconColor =
    | IconColors.Default
    | IconColors.Error
    | IconColors.White
    | IconColors.Blue
    | IconColors.DarkBlue
    | IconColors.DarkGray
    | IconColors.KashmirBlue
    | IconColors.Green
    | IconColors.Yellow
    | IconColors.Grey
    | IconColors.LightGray
    | IconColors.LightBlack
    | IconColors.Red
    | IconColors.Black;

export type BorderSize = BorderSizeOption.THIN | BorderSizeOption.THICK;

export type IconSize =
    | IconSizes.XXLarge
    | IconSizes.XLarge
    | IconSizes.Large
    | IconSizes.Medium
    | IconSizes.Small
    | IconSizes.XSmall
    | IconSizes.MobileLarge
    | IconSizes.LargeObsolete
    | IconSizes.MobileLargeObsolete;

export type IconAnimation = IconAnimations.None | IconAnimations.Rotate;

export type BorderShape = BorderShapes.Circle | BorderShapes.Square;

const noBorderSupportIcons = ['clock', 'envelop', 'arrows-circle', 'tos', 'tag', 'share'];

interface IGenericIconDefaults {
    colorName?: IconColor;
    sizeName?: IconSize;
    reverseColor?: boolean;
    animation?: IconAnimation;
    noBorder?: boolean;
    borderSize?: BorderSize;
    borderShape?: BorderShape;
    backgroundTransparency?: BackgroundTransparencies;
    iconTransparency?: IconTransparencies;
}

export interface IGenericIcon extends IGenericIconDefaults, IDesignable {
    iconName: string;
}

export class BorderRoundedIcon extends TechseeBaseComponent<IGenericIcon> {
    renderInternal(): JSX.Element {
        const noBorder = noBorderSupportIcons.indexOf(this.props.iconName) > -1 ? true : this.props.noBorder;

        this.addCssClass('border-rounded-icon');
        this.addCssClass(this.props.className);
        this.addCssClass('color-' + this.props.colorName);
        this.addCssClass('size-' + this.props.sizeName);
        this.addCssClass(`icon icon-${this.props.iconName}`);
        this.addCssClassIf('no-border', noBorder);
        this.addCssClassIf('reverse-color', this.props.reverseColor);
        this.addCssClassIf('thick-border-size', this.props.borderSize === BorderSizeOption.THICK);
        this.addCssClassIf(`animate--${this.props.animation}`, this.props.animation !== 'none');
        this.addCssClassIf('border-square', this.props.borderShape === BorderShapes.Square);

        this.addCssClassIf('transparent-p50', this.props.backgroundTransparency === BackgroundTransparencies.HALF);
        this.addCssClassIf('transparent-p10', this.props.backgroundTransparency === BackgroundTransparencies.TEN);
        this.addCssClassIf('transparent-p0', this.props.backgroundTransparency === BackgroundTransparencies.FULL);

        this.addCssClassIf('icon-transparent-p50', this.props.iconTransparency === IconTransparencies.HALF);
        this.addCssClassIf('icon-transparent-p0', this.props.iconTransparency === IconTransparencies.FULL);

        return <i className={this.renderCssClasses()} />;
    }

    static defaultProps: Readonly<IGenericIconDefaults> = {
        colorName: IconColors.Default,
        sizeName: IconSizes.XLarge,
        animation: IconAnimations.None,
        noBorder: false,
        reverseColor: false,
        borderSize: BorderSizeOption.THIN,
        borderShape: BorderShapes.Circle,
        backgroundTransparency: BackgroundTransparencies.NONE,
        iconTransparency: IconTransparencies.NONE
    };
}
