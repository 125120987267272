'use strict';
import tsChatView from './ts-chat.view.html';
import './ts-chat.style.scss';

class tsChatController {
    constructor(tsChatApi, $rootScope, $localStorage) {
        'ngInject';
        this.chatApi = tsChatApi;
        this.rootScope = $rootScope;
        this.localStorage = $localStorage;
    }

    get theme() {
        return this.rootScope.THEME || '';
    }

    get mobileBranding() {
        return this.localStorage.mobileBranding || {};
    }

    get requireImage() {
        return this.rootScope.requireImage;
    }

    getMessageBackground(message) {
        if (!message.isVideo) {
            return `url('${message.data}')`;
        }

        return `url('../../../../img/video-thumbnail.png'), url('${message.data}')`;
    }

    getChatMessageCssClasses(message) {
        const isAgentMessage = message.type.match(/-DB$/);

        return {
            'message-left': isAgentMessage,
            'message-right': !isAgentMessage
        };
    }

    isMediaMessage(message) {
        return (
            message.type === 'IMG-DB' ||
            message.type === 'IMG-CL' ||
            message.type === 'VID-DB' ||
            message.type === 'VID-CL'
        );
    }

    retryImageUpload(index, event) {
        event.stopPropagation();

        if (this.onRetryImageUploadClicked) {
            this.onRetryImageUploadClicked(index);
        }
    }
}

export function tsChatDirective() {
    'ngInject';

    return {
        template: tsChatView,
        replace: true,
        restrict: 'EA',
        transclude: true,
        scope: {},
        bindToController: {
            messageHistory: '=',
            onImageClicked: '&',
            onRetryImageUploadClicked: '&',
            showMediaIndexes: '='
        },
        controller: tsChatController,
        controllerAs: 'vm'
    };
}
