'use strict';

import get from 'lodash/get';

import {STATUS_MESSAGES} from '../../states/meeting/meeting.settings.js';
import {FlowType} from '@techsee/techsee-common/lib/constants/room.constants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

// TOS Dialog controller and view
import termsNoticeView from '../../states/meeting/dialogs/terms-notice/terms-notice.view.html';
import {TermsNoticeController} from '../../states/meeting/dialogs/terms-notice/terms-notice.controller.js';

export class TsTermsAndConditions {
    constructor($uibModal, tsBrowserUtilsService, db, tsEventService, ROLES) {
        'ngInject';

        this.tsBrowserUtilsService = tsBrowserUtilsService;
        this.db = db;
        this.$uibModal = $uibModal;
        this.EventService = tsEventService;
        this.ROLES = ROLES;
    }

    syncTOS(chatApi, mode, role) {
        return new Promise((resolve, reject) => {
            const tosAcceptedRoom = this.tsBrowserUtilsService.getFromLocalStorage('tos_accepted'),
                tosAccepted = tosAcceptedRoom === chatApi.roomId || chatApi.client.tosAccepted === true;

            const accountSettings = chatApi.accountSettings;
            const displayTerms = get(accountSettings, 'displayTerms');

            if (
                tosAccepted ||
                !displayTerms ||
                (role === this.ROLES.TECHNICIAN &&
                    (get(chatApi, 'client.flowType') === FlowType.fs || chatApi.roomCode)) ||
                this.modalFirst ||
                this.lastAbortedMode === mode
            ) {
                if (role === this.ROLES.TECHNICIAN) {
                    this.EventService.sendEventLog(
                        'none',
                        chatApi.roomId || 'none',
                        STATUS_MESSAGES.FIELD_SERVICES_SKIP_TOS
                    );
                }

                // If terms is disabled, it's the same result as approving terms
                return resolve(tosAccepted || displayTerms === false || role === this.ROLES.TECHNICIAN);
            }

            this.modalFirst = true;

            const accountId = accountSettings.accountId;

            const {displayAccountTerms, displayTechseeTerms, displayPrivacyTerms} = accountSettings;

            const termsInfo = {
                companyName: accountSettings.companyName,
                termsURL: displayTechseeTerms && accountSettings.termsURL,
                accountTermsUrl: displayAccountTerms && accountSettings.accountTermsUrl,
                privacyURL: displayPrivacyTerms && accountSettings.privacyURL,
                allowTermsMessage: accountSettings.allowTermsMessage,
                customCancelMessage: accountSettings.customCancelMessage
            };

            // if the account has custom terms string, set a promise to
            const termsTranslationPromise = chatApi.accountSettings.useCustomTermsMessage
                ? this.db.Account.termsTranslationStrings(accountId, {
                      params: {
                          language: accountSettings.clientLanguage
                      }
                  })
                : Promise.resolve({data: {}});

            termsTranslationPromise.then(({data}) => {
                const {WAITING_FOR_TOS_ACCEPTANCE} = STATUS_MESSAGES;
                const windowClass = 'terms-notice-modal';

                this.EventService.sendEventLog('none', chatApi.roomId || 'none', WAITING_FOR_TOS_ACCEPTANCE);
                chatApi.sendLog(WAITING_FOR_TOS_ACCEPTANCE);

                this.tosModalInstance = this.$uibModal.open({
                    animation: false,
                    backdrop: 'static',
                    template: termsNoticeView,
                    controller: TermsNoticeController,
                    controllerAs: 'vm',
                    windowClass,
                    resolve: {
                        termsInfo: termsInfo,
                        termsTranslationStrings: data
                    }
                });

                return this.tosModalInstance.result
                    .then(() => {
                        this.tsBrowserUtilsService.saveToLocalStorage('tos_accepted', chatApi.roomId);
                        this.tosModalInstance = null;
                        resolve(true);
                    })
                    .catch((err) => {
                        this.modalFirst = false;

                        const rejectReason = err && err.abortedTerms ? {abortedTerms: true} : {rejectedTerms: true};

                        if (rejectReason.abortedTerms) {
                            this.lastAbortedMode = mode;
                        }

                        this.tosModalInstance = null;
                        reject(rejectReason);
                    });
            });
        });
    }

    abort(chatApi) {
        if (!this.tosModalInstance) {
            return;
        }

        chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.PREPARING, false);

        return this.tosModalInstance.dismiss({abortedTerms: true});
    }
}
