'use strict';

import {getSubDomainFromUrl} from '@techsee/techsee-common/lib/utils';
import includes from 'lodash/includes';

export function isSoloSubdomain() {
    const soloSubdomains = String(SOLO_SUBDOMAINS).split(',');

    return includes(soloSubdomains, getSubDomainFromUrl(window.location.href));
}

export class TsSoloService {
    constructor($location, auth, tsBrowserUtilsService) {
        'ngInject';

        this.isSoloSubdomain = isSoloSubdomain();
        this.startParams = {};
        this.paramsByName = ['workOrderId', 'workOrderNumber', 'caseId', 'caseNumber', 'techName'];
        this.UrlProtocol = 'https://';
        this.UrlEnvPrefix = $location.host().split('.')[0];
        this.basicPartOfURL = '.techsee.me/menu?';
        this.auth = auth;
        this.browserUtilsService = tsBrowserUtilsService;
    }

    get isSolo() {
        return this.isSoloSubdomain;
    }

    storeParamsInStorage(startStateParams = {}) {
        if (!this.isSolo) {
            return;
        }

        // init the params stored in startParams
        this.startParams = {};
        this.paramsByName.forEach((param) => {
            //clear session Storage from any params that set before
            this.browserUtilsService.removeFromSessionStorage(param);

            //save the relevant params into the session storage and variable
            if (Object.prototype.hasOwnProperty.call(startStateParams, param) && startStateParams[param]) {
                this.startParams[param] = startStateParams[param];
                this.browserUtilsService.saveToSessionStorage(param, startStateParams[param]);
            }
        });
    }

    getAbsUrlAfterEndMeeting(UrlEnvPrefix = this.UrlEnvPrefix) {
        if (!this.isSolo) {
            return Promise.resolve();
        }

        return this.auth
            .createOneTimeToken()
            .then(
                (ott) =>
                    `${this.UrlProtocol}${UrlEnvPrefix}${
                        this.basicPartOfURL
                    }${this.getStartParamsAsStringForURL()}&ott=${ott}`
            );
    }

    getStartParamsAsStringForURL() {
        return (
            this.getParamsAsURLString((param) => this.startParams[param]) ||
            this.getParamsAsURLString((param) => this.browserUtilsService.getFromSessionStorage(param))
        );
    }

    getParamsAsURLString(checkParamIfExist) {
        let paramsAsURLString = '';
        let ampersand = '';

        this.paramsByName.forEach((param) => {
            const paramValue = checkParamIfExist(param);

            if (paramValue) {
                paramsAsURLString = `${paramsAsURLString}${ampersand}${param}=${paramValue}`;
                ampersand = '&';
            }
        });

        return paramsAsURLString;
    }
}
