'use strict';

import forEach from 'lodash/forEach';

export class TsAppstoreUrlUtilsService {
    constructor(tsChatApi, $localStorage) {
        'ngInject';

        this.chatApi = tsChatApi;
        this.$localStorage = $localStorage;
    }

    /**
     * Returns true if url is an appstore link
     */
    isAppstoreLink(url) {
        let appstoreLink = false;
        const appStoreURLsArray = String(APP_STORE_URLS).split(',');

        forEach(appStoreURLsArray, (appStoreURL) => {
            const urlPattern = new RegExp(decodeURI(appStoreURL));

            if (urlPattern.test(url)) {
                appstoreLink = true;
            }
        });

        return appstoreLink;
    }

    prepareAppstoreRedirect() {
        const params = {
            roomId: this.chatApi.roomId,
            userUniqueId: this.chatApi.getCurrentUserUniqueId()
        };

        const query = Object.keys(params)
                .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
                .join('&'),
            //Set cookie expires date to one hour ahead
            expires = new Date(Date.now() + 1000 * 60 * 60).toGMTString();

        document.cookie = `roomLink=https://${window.location.host}/?${query}; domain=.techsee.me; path=/; expires=${expires}`;
    }
}
