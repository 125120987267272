'use strict';

/**
 * Network information service
 *
 * https://wicg.github.io/netinfo/#use-cases-and-requirements
 * https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
 */

import {EventEmitter} from 'events';

export interface ITsNetworkInfo {
    lastConnectionType: string;
    connectionType: string;
    downlinkMax: string;

    setLastConnectionType(value: string): void;
    toJSON(): {connectionType: string; downlinkMax: string};
}

export class TsNetworkInfo extends EventEmitter implements ITsNetworkInfo {
    private _connectionType = '';
    private _lastConnectionType = '';
    private _downlinkMax = '';

    constructor() {
        'ngInject';
        super();

        // @ts-ignore
        if (navigator.connection && navigator.connection.addEventListener) {
            this.initParams();

            // @ts-ignore
            navigator.connection.addEventListener('change', () => {
                this.initParams();
            });
        }
    }

    initParams() {
        this._connectionType = (navigator as any).connection.type
            ? (navigator as any).connection.type
            : (navigator as any).connection.effectiveType;
        this._downlinkMax = (navigator as any).connection.downlinkMax;
    }

    setLastConnectionType(value: string) {
        this._lastConnectionType = value;
    }

    get lastConnectionType() {
        return this._lastConnectionType;
    }

    get connectionType() {
        return this._connectionType ? String(this._connectionType) : 'Unsupported';
    }

    get downlinkMax() {
        return this._downlinkMax ? String(this._downlinkMax) : 'Unsupported';
    }

    toJSON() {
        return {
            connectionType: this._connectionType,
            downlinkMax: this._downlinkMax
        };
    }
}
