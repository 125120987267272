'use strict';

import get from 'lodash/get';

import {STATUS_MESSAGES, CAMERA_ACCESS, ROOM_MODES, GALLERY_VIEW_PAGES} from '../meeting.settings.js';
import {UserType} from '@techsee/techsee-common/lib/constants/room.constants';
import {KnownMediaStream} from '@techsee/techsee-media-service/lib/MediaConstants';
import {MeetingState} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import {MeetingStateControllerBase} from '../meeting.state.controller.base';
import {speedtestUrl} from '@techsee/techsee-common/lib/constants/account.constants.js';
import {getMeetingTracer} from '../meeting.tracer';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

const trace = getMeetingTracer('MeetingOneClickController');

export class OneClickPhotoChatController extends MeetingStateControllerBase {
    constructor(
        $localStorage,
        $sessionStorage,
        $rootScope,
        $scope,
        $stateParams,
        $timeout,
        $uibModal,
        $window,
        $sce,
        $translate,
        db,
        roomInfo,
        tsEnvironmentDetect,
        tsChatApi,
        tsChatHelper,
        tsFullscreenGalleryService,
        tsImageFixer,
        tsStateHelper,
        tsTermsAndConditions,
        tsVisibilityChange,
        tsEventService,
        preCameraPermissionService,
        tsTestUpload,
        currentUser,
        mobileAppMediaService,
        audioService
    ) {
        'ngInject';

        super($rootScope, $scope, MeetingState.OneClick, db, tsEventService);

        this.mobileAppMediaService = mobileAppMediaService;

        this.$scope = $scope;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$sce = $sce;
        this.translate = $translate;
        this.stateHelper = tsStateHelper;
        this.audioService = audioService;
        this.db = db;
        this.tsTermsAndConditions = tsTermsAndConditions;
        this.chatApi = tsChatApi;
        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
        this.endParams = $stateParams.csi ? {csi: $stateParams.csi} : {};
        this.roomId = roomInfo.roomId;
        this.isWebRTCEnabledAppleDevice = tsEnvironmentDetect.isWebRTCEnabledAppleDevice();
        this.ROOM_MODES = ROOM_MODES;
        this.$rootScope = $rootScope;
        this.tsFullscreenGalleryService = tsFullscreenGalleryService;
        this.imageFixer = tsImageFixer;
        this.chatHelper = tsChatHelper;
        this.EventService = tsEventService;
        this.preCameraPermissionService = preCameraPermissionService;
        this.testUpload = tsTestUpload;
        this.currentRole = currentUser && currentUser.role;
        this.GALLERY_VIEW_PAGES = GALLERY_VIEW_PAGES;
        this.runSpeedTestOnClient = this.$sessionStorage.runSpeedTestOnClient;

        this.takeSnapshotButtonAria = this.translate.instant('IMAGE_UPLOAD.VIEW.TAKE_SNAPSHOT_BUTTON_ARIA');

        this.chooseFromGalleryButtonAria = this.translate.instant('IMAGE_UPLOAD.VIEW.CHOOSE_FROM_GALLERY_BUTTON_ARIA');

        this.expandButtonAria = this.translate.instant('IMAGE_UPLOAD.VIEW.EXPAND_BUTTON_ARIA');

        // Outgoing image url
        this.photoSrc = null;

        this.showGuidance = true;

        this.$scope.$on('$viewContentLoaded', () => {
            // make the code execute on the next cycle
            this.$timeout(() => {
                this.EventService.sendEventLog('none', this.roomId || 'none', STATUS_MESSAGES.CLIENT_PHOTO_VIEW_LOADED);
            }, 0);
        });

        this.imageSendingCompletion = this.imageSendingCompletion.bind(this);
        this._onHistoryMessageReceived = this._onHistoryMessageReceived.bind(this);
        this._cameraApprovalDialogStateChange = this._cameraApprovalDialogStateChange.bind(this);

        this.visibilityChange = tsVisibilityChange;

        this.setVisibilityEventsState(true);

        this.messageHistory = tsChatHelper.messageHistory;

        this.notifyConstructionIsReady();
    }

    //#region Meeting Pipeline

    get handshakeFailureStatusMessage() {
        return STATUS_MESSAGES.ONE_CLICK_PHOTO_STREAM_FAILED;
    }

    setVisibilityEventsState(enableVisibilityEvents) {
        if (this.isWebRTCEnabledAppleDevice && enableVisibilityEvents) {
            this.visibilityChange.enableVisibilityEvents();
        } else {
            this.visibilityChange.disableVisibilityEvents();
        }
    }

    meetingModeHandshake() {
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_ON_SCREEN_SHARE_TURN_OFF_GUIDANCE_IOS, false);
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.SCREEN_SHARE_CAPTURING_APPROVED_IOS, false);

        return this.tsTermsAndConditions
            .syncTOS(this.chatApi, ROOM_MODES.oneClick, this.currentRole)
            .then((isApproved) => {
                if (isApproved) {
                    let cameraApprovalTimeout = null;

                    return this.preCameraPermissionService
                        .cameraPreApprove()
                        .then(() => {
                            cameraApprovalTimeout = setTimeout(this._cameraApprovalDialogStateChange, 1000);
                            this.mobileAppMediaService.setLoopbackCameraSource(
                                get(this.chatApi, 'client.clientDevice')
                            );

                            trace.info('before initLocalMediaStreams to create media streams');

                            return this.mobileAppMediaService.initLocalMediaStreams();
                        })
                        .then(() => {
                            clearTimeout(cameraApprovalTimeout);
                            this.chatHelper.setMode();
                            this.chatApi.setStatus(
                                socketEvents.CLIENT_OUT_SET_STATUS.ONE_CLICK_HANDSHAKE_SUCCESS,
                                true
                            );
                            this._cameraApprovalDialogStateChange(false);
                            trace.info('oneClickHandshakeSuccess and camera approval');

                            this.chatApi.sendLog(STATUS_MESSAGES.CONNECTED_IN_PHOTO_MODE);
                            this.chatApi.sendLog(STATUS_MESSAGES.MEDIA_PERMISSION_ALLOW);

                            if (this.chatApi.client.videoSupport) {
                                this.$localStorage.wasInVideoMode = true;
                            }

                            if (this.chatApi.enableSilentUploadTest) {
                                this.testUpload.exec(this.chatApi.roomId, this.chatApi.imageSharingQuality === 1);
                            }

                            this.mobileAppMediaService.pauseCameraStream(false);

                            this.EventService.sendEventLog(
                                'none',
                                this.chatApi.roomId,
                                STATUS_MESSAGES.MEDIA_PERMISSION_ALLOW,
                                {description: 'Customer approved camera access', origin: ROOM_MODES.oneClick}
                            );

                            this.db.Rooms.setReportedField(this.roomId, {
                                data: {
                                    event: {
                                        key: 'cameraAccess',
                                        value: CAMERA_ACCESS.CAMERA_APPROVED
                                    }
                                }
                            });

                            this.db.Rooms.setReportedField(this.roomId, {
                                data: {
                                    event: {
                                        key: 'streamingIntent',
                                        value: ROOM_MODES.oneClick,
                                        type: 'push'
                                    }
                                }
                            });

                            this.db.Rooms.createInstance({_id: this.chatApi.roomId}).changeAgent(ROOM_MODES.oneClick);
                        })
                        .catch((err) => {
                            trace.info(
                                'something went wrong in meetingModeHandshake- set inCameraApprovalDialog to false. err:',
                                err
                            );

                            if (this.tsChatApi.client.inCameraApprovalDialog) {
                                this._cameraApprovalDialogStateChange(false);
                            }

                            throw err;
                        })
                        .finally(() => {
                            clearTimeout(cameraApprovalTimeout);
                        });
                }
            });
    }

    syncMeetingState() {
        if (!this.chatApi.connected) {
            return this.chatHelper._setCurrentPage(GALLERY_VIEW_PAGES.MAIN);
        }

        const mobileSpeedtestDefaultProvider = get(this.chatApi, 'accountSettings.mobileSpeedtestDefaultProvider');

        this.speedtestUrl = mobileSpeedtestDefaultProvider
            ? speedtestUrl.ookla
            : get(this.chatApi, 'accountSettings.mobileSpeedtestUrl');
        this.SPEEDTEST_URL = this.$sce.trustAsResourceUrl(this.speedtestUrl);
    }

    initModeHandlers() {
        const runSpeedtestListener = (value) => this._showSpeedtestPage(value);

        this.chatApi.on(socketEvents.CLIENT_IN.NEW_HISTORY_MESSAGE_RECEIVED, this._onHistoryMessageReceived);
        this.chatApi.on(socketEvents.CLIENT_IN.RUN_SPEED_TEST_ACTION, runSpeedtestListener);

        const visibleListener = () => {
            if (!this.visibilityChange.isDisabled()) {
                this.$window.location.reload();
            }
        };

        this.visibilityChange.on('visible', visibleListener);

        this.$scope.$on('$destroy', () => {
            this.chatHelper.closePopups();

            this.chatApi.off(socketEvents.CLIENT_IN.NEW_HISTORY_MESSAGE_RECEIVED, this._onHistoryMessageReceived);
            this.chatApi.off(socketEvents.CLIENT_IN.RUN_SPEED_TEST_ACTION, runSpeedtestListener);

            this.visibilityChange.off('visible', visibleListener);
        });
    }

    //#endregion Meeting Pipeline

    get loopbackStreamType() {
        return KnownMediaStream.USER_VIDEO_STREAM;
    }

    _showSpeedtestPage(value) {
        this.runSpeedTestOnClient = true;
        this.$sessionStorage.runSpeedTestOnClient = this.runSpeedTestOnClient;

        if (value) {
            this.chatHelper.setPage(GALLERY_VIEW_PAGES.SPEEDTEST);
        }
    }

    _cameraApprovalDialogStateChange(value = true) {
        this.chatApi.cameraApprovalDialogStateChange(value);
    }

    displayExpandButton() {
        return (
            this.chatApi.connected && (this.messageHistory.messages.length > 0 || this.chatHelper.chatControlFocused)
        );
    }

    displaySpeedtestButton() {
        return (
            get(this.chatApi, 'accountSettings.enableMobileSpeedtest') &&
            this.runSpeedTestOnClient &&
            this.chatHelper.isInMainPage
        );
    }

    _onHistoryMessageReceived(newHistoryMessageData) {
        const {isPartialSet, firstMessageInSet, message} = newHistoryMessageData;

        this.messageHistory = this.chatHelper.messageHistory;

        if (isPartialSet && isPartialSet !== this.chatHelper.PARTIAL_SET_STATE.COMPLETE) {
            return;
        }

        const isMessageFromClient = get(message, 'sender') === UserType.client;

        if (!message.isNew || isMessageFromClient) {
            return;
        }

        if (this.tsFullscreenGalleryService.isSupportedMsg(message)) {
            if (isPartialSet === this.chatHelper.PARTIAL_SET_STATE.COMPLETE) {
                return this.chatHelper.displayFullscreenGallery(
                    this.tsFullscreenGalleryService.$context.SET_COMPLETED,
                    firstMessageInSet
                );
            }

            return this.chatHelper.displayFullscreenGallery(
                this.tsFullscreenGalleryService.$context.NEW_MESSAGE_RECEIVED
            );
        }

        // New text message from dashboard
        return this.chatHelper._setCurrentPage(GALLERY_VIEW_PAGES.CHAT);
    }

    takeSnapshot() {
        this.showGuidance = false;

        const options = {
            format: this.imageFixer.settings.defaultMime,
            quality: this.imageFixer.settings.imageQuality
        };

        this.playAudio('shutter.mp3');

        // Shutter effect - should be done in CSS instead...
        this.shutter = true;
        this.$timeout(() => {
            this.shutter = false;
        }, 200);

        this.mobileAppMediaService
            .getSnapshotFromKnownStream(KnownMediaStream.USER_VIDEO_STREAM, options)
            .then((snapshotResult) => {
                this.sendPhoto(snapshotResult.objectUrl);
            })
            .catch(() => undefined);
    }

    // File Loader Handlers
    loadOpen() {
        this.setVisibilityEventsState(false);
    }

    loadStart() {
        this.setVisibilityEventsState(false);
    }

    loadFinished(objUrl) {
        this.showGuidance = false;

        //TODO - Alex: Need to check if the line below is needed, because stream management should handle this case.
        // Since the image might have been taken by camera, it will take control of video
        this.syncMeetingState();
        this.setVisibilityEventsState(true);

        this.sendPhoto(objUrl, undefined, this.imageSendingCompletion);
    }

    imageSendingCompletion() {
        if (!this.visibilityChange.isDisabled() && this.audioService.isAudioSupported) {
            this.$window.location.reload();
        }
    }

    loadAborted() {
        //TODO - Alex: Need to check if the line below is needed, because stream management should handle this case.
        // Since the image might have been taken by camera, it will take control of video
        this.syncMeetingState();
        this.setVisibilityEventsState(true);
    }

    showMediaButtons() {
        return (
            get(this.chatApi, 'accountSettings.allowUploadImagesFromVideoAndPhoto') &&
            !this.chatHelper.isInSpeedtestPage
        );
    }

    showImageUploadSuccessNotification() {
        return this.chatHelper.showImageUploadSuccess;
    }

    showImageUploadFailureNotification() {
        return this.chatHelper.showImageUploadFailure;
    }

    sendPhoto(photoSrc, retryIndex, imageSendingCompletion) {
        const sendPhotoOptions = {
            photoSrc: photoSrc,
            origin: ROOM_MODES.oneClick,
            withConfirm: true,
            withLoader: true,
            retryIndex: retryIndex,
            imageSendingCompletion: imageSendingCompletion
        };

        this.chatHelper.sendMedia(sendPhotoOptions).catch(() => this.imageSendingCompletion());
    }

    playAudio(file) {
        const audio = this.$rootScope.requireAudio(file);

        const playPromise = audio.play();

        if (playPromise) {
            playPromise.catch((err) => {
                console.error(`Playing audio ${file} failed because of error: ${err}`);
            });
        }
    }

    retryImageUpload(index) {
        this.sendPhoto(this.messageHistory.messages[index].data, index);
    }
}
