import {ITsEnvironmentDetect, TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {MeetingController} from './meeting.controller';
import {
    IVisibilityChange,
    TsVisibilityChange
} from '../../../services/ts-visibility-change/ts-visibility-change.service';
import {BrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {ITsNetworkInfo, TsNetworkInfo} from '../../../services/ts-network-info/ts-network-info.service';
import {ITsEventService, TsEventService} from '../../../services/ts-event-service/ts-event-service';
import {TsTranslationHelper} from '../../../services/ts-translation-helper/ts-translation-helper.service';
import {TsBrowserDetectService} from '../../../services/ts-browser-detect/ts-browser-detect.service';
import {IRoomInfo, IUser} from './interfaces/AngularInterfaces';
import {DetectWebRtcService} from '@techsee/techsee-client-infra/lib/services/DetectWebRtcService';
import {IDbCustomStrings, LocalizationService} from '../../services/LocalizationService';

export interface IMeetingStores {
    [name: string]: any;
}

export class MeetingStores {
    public stores?: IMeetingStores;

    private _rootScope: any;
    private _stateParams: any;
    private _tsStateHelper: any;
    private _db: any;
    private $localStorage: any;
    private _tsChatApi: any;
    private _environmentService?: ITsEnvironmentDetect;
    private _visibilityChange: IVisibilityChange;
    private _roomInfo: IRoomInfo;
    private _currentUser: IUser;
    private _networkInfoService: ITsNetworkInfo;
    private _tsEventService: ITsEventService;
    private _tsUrlUtils: any;
    private _tsWebRtcDetector: DetectWebRtcService;
    private _localizationService: LocalizationService;
    private _angularTranslate: any;

    //We use this type of injection, because ng-annotate-loader not runs on TypeScript files.
    static $inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        'tsStateHelper',
        '$translate',
        'db',
        '$localStorage',
        'tsChatApi',
        'roomInfo',
        'currentUser',
        'tsUrlUtils',
        'tsWebRtcDetector'
    ];

    constructor(
        $scope: any,
        $rootScope: any,
        $stateParams: any,
        tsStateHelper: any,
        $translate: any,
        db: any,
        $localStorage: any,
        tsChatApi: any,
        roomInfo: IRoomInfo,
        currentUser: IUser,
        tsUrlUtils: any,
        tsWebRtcDetector: DetectWebRtcService
    ) {
        this._rootScope = $rootScope;
        this._stateParams = $stateParams;
        this._tsStateHelper = tsStateHelper;
        this._angularTranslate = $translate;
        this._db = db;
        this.$localStorage = $localStorage;
        this._localizationService = new LocalizationService(db.CustomStrings as IDbCustomStrings);
        this._tsChatApi = tsChatApi;
        this._visibilityChange = new TsVisibilityChange(window);
        this._roomInfo = roomInfo;
        this._currentUser = currentUser;
        this._networkInfoService = new TsNetworkInfo();
        this._tsEventService = new TsEventService(this._db);
        this._tsUrlUtils = tsUrlUtils;
        this._tsWebRtcDetector = tsWebRtcDetector;

        this.initStores().then((stores) => {
            $scope.$apply(() => (this.stores = stores));
        });
    }

    private initAngularTranslate(): Promise<any> {
        return new Promise((resolve) => {
            this._angularTranslate.onReady(resolve);
        });
    }

    private async initStores(): Promise<IMeetingStores> {
        this._environmentService = await TsEnvironmentDetect.create();

        return new Promise((resolve) => {
            Promise.all([this._localizationService.init(), this.initAngularTranslate()]).then(() => {
                const stores: IMeetingStores = {};
                const translationHelper = new TsTranslationHelper(this._db);
                const browserUtilsService = new BrowserUtilsService(window);
                const tsBrowserDetect = new TsBrowserDetectService(window, this._db, this._tsUrlUtils);
                const endParams = this._stateParams.csi ? {csi: this._stateParams.csi} : {};

                const meetingController = new MeetingController(
                    this._localizationService,
                    this._angularTranslate,
                    this._visibilityChange,
                    this._tsChatApi,
                    this._db,
                    this.$localStorage,
                    this._roomInfo,
                    this._currentUser,
                    this._tsEventService,
                    browserUtilsService,
                    this._networkInfoService,
                    translationHelper,
                    this._tsStateHelper,
                    tsBrowserDetect,
                    this._tsWebRtcDetector,
                    endParams,
                    this._rootScope.THEME,
                    this._environmentService!
                );

                meetingController.init().then(() => {
                    defineStore('translate', this._localizationService.translate);
                    defineStore('requireImage', this._rootScope.requireImage);
                    defineStore('tsEnvironmentDetect', this._environmentService);
                    defineStore('meetingController', meetingController);
                    defineStore('endMeetingController', meetingController.endMeetingController);
                    defineStore('videoController', meetingController.videoController);

                    resolve(stores);

                    /*
                     Because of technical issue with Angular digest loop,
                     We define stores as non enumerable properties. This helps to avoid infinite digest of angular.
                    */
                    function defineStore(storeName: string, value: any) {
                        Object.defineProperty(stores, storeName, {
                            value: value,
                            enumerable: false
                        });
                    }
                });
            });
        });
    }
}
