'use strict';

import {AuthService} from './auth/auth.service';
import {DbService} from './db/db.service';
import {TsBrowserDetectService} from './ts-browser-detect/ts-browser-detect.service';
import {TsUrlUtilsService} from './ts-url-utils/ts-url-utils.service';
import {TsAppstoreUrlUtilsService} from './ts-appstore-url-utils/ts-appstore-url-utils.service';
import {TsImageFixerService} from './ts-image-fixer/ts-image-fixer.service';
import {TsVideoFixerService} from './ts-video-fixer/ts-video-fixer.service';
import {TsStateHelperService} from './ts-state-helper/ts-state-helper.service';
import {TsVisibilityChange} from './ts-visibility-change/ts-visibility-change.service';
import {TokenInterceptor} from './token-interceptor/token-interceptor.service';
import {TsExceptionHandler} from './ts-exception-handler/ts-exception-handler.service';
import {TsNetworkInfo} from './ts-network-info/ts-network-info.service';
import {CustomTranslateLoader} from './custom-translate-loader/custom-translate-loader.service';
import {TsTranslationHelper} from './ts-translation-helper/ts-translation-helper.service';
import {TsTermsAndConditions} from './ts-terms-and-condition/ts-terms-and-condition.service';
import {TsChatHelper} from './ts-chat-helper/ts-chat-helper.service';
import {TsFlashlightHelper} from './ts-flashlight-helper/ts-flashlight-helper.service';
import {TsEventService} from './ts-event-service/ts-event-service';
import {TsCobrowsingService} from './ts-cobrowsing/ts-cobrowsing-service.service';
import {TsEndMeetingModalService} from './ts-end-meeting/ts-end-meeting-modal.service';
import {TsTestUpload} from './ts-test-upload/ts-test-upload.service';
import {TsPushNotificationService} from './ts-push-notification/ts-push-notification.service';
import {BrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {
    DETECT_RTC_ADVISED_TIMEOUT,
    DetectWebRtcService
} from '@techsee/techsee-client-infra/lib/services/DetectWebRtcService';
import {DefaultRoomChannelTracer} from '@techsee/techsee-client-infra/lib/infra/RoomChannelTracer';
import {TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {TsSoloService} from './ts-solo/ts-solo.service';
import {MobileChatApiService} from './ts-chat-api/MobileChatApi';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';

const environmentDetect = await TsEnvironmentDetect.create();

export default angular
    .module('app.services', ['tsUrlConfig.service'])
    .service('auth', AuthService)
    .service('db', DbService)
    .service('tsUrlUtils', TsUrlUtilsService)
    .service('tsBrowserDetect', ['$window', 'db', 'tsUrlUtils', TsBrowserDetectService])
    .service('tsChatApi', () => new MobileChatApiService(PlatformType.mobile_web, $.ajax))
    .service('tsAppstoreUrlUtils', TsAppstoreUrlUtilsService)
    .service('tsImageFixer', TsImageFixerService)
    .service('tsVideoFixer', TsVideoFixerService)
    .service('tsStateHelper', TsStateHelperService)
    .service('tsVisibilityChange', ['$window', TsVisibilityChange])
    .service('tokenInterceptor', TokenInterceptor)
    .service('tsExceptionHandler', TsExceptionHandler)
    .service('tsNetworkInfo', TsNetworkInfo)
    .service('customTranslateLoader', CustomTranslateLoader)
    .service('tsTranslationHelper', TsTranslationHelper)
    .service('tsTermsAndConditions', TsTermsAndConditions)
    .service('tsChatHelper', TsChatHelper)
    .service('tsFlashlightHelper', TsFlashlightHelper)
    .service('tsEventService', TsEventService)
    .service('tsCobrowsingService', TsCobrowsingService)
    .service('tsEndMeetingModalService', TsEndMeetingModalService)
    .service('tsTestUpload', TsTestUpload)
    .service('tsPushNotificationService', TsPushNotificationService)
    .service('tsBrowserUtilsService', ['$window', BrowserUtilsService])
    .constant('DETECT_RTC_TIMEOUT_SECONDS', DETECT_RTC_ADVISED_TIMEOUT)
    .service('tsWebRtcDetector', ['DETECT_RTC_TIMEOUT_SECONDS', DetectWebRtcService])
    .service('tsRoomChannelTracer', DefaultRoomChannelTracer)
    .service('tsEnvironmentDetect', () => environmentDetect)
    .service('tsSoloService', TsSoloService);
