'use strict';

import EXIF from '../../../js/exif';
import exifOrient from 'exif-orient/exif-orient';
import {FIX_SETTINGS} from './ts-video-fixer.settings.js';

/**
 * Utility service that wraps the functionality required to re-orient and
 * resize an video
 */
export class TsVideoFixerService {
    constructor($window) {
        'ngInject';
        this.$window = $window;
        this.URL = this.$window.URL;

        this.EXIF = EXIF;
        this.exifOrient = exifOrient;
    }

    fix(videoFile) {
        return new Promise((resolve, reject) => {
            this._videoToObjectUrl(videoFile)
                .then((url) => {
                    const video = document.createElement('video');

                    video.addEventListener('loadeddata', () => {
                        video.pause();

                        resolve({url, duration: video.duration, size: videoFile.size, file: videoFile});
                    });
                    video.addEventListener('error', () => {
                        reject('Video failed to load');
                    });

                    video.crossOrigin = 'Anonymous';
                    video.preload = 'metadata';
                    video.muted = true;
                    video.playsInline = true;
                    video.autoplay = true;
                    video.src = url;

                    // Support IOS 8.x and below
                    video.load();
                })
                .catch((err) => {
                    reject(`Video failed to load. err=${err}`);
                });
        });
    }

    createThumbnail(videoFile) {
        return new Promise((resolve, reject) => {
            const url = this.URL.createObjectURL(videoFile);

            const video = document.createElement('video');

            let thumbnailCreated = false;

            const snapImage = (video, cb) => {
                if (thumbnailCreated) {
                    cb(null);

                    return;
                }

                const canvas = document.createElement('canvas');

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                const ctx = canvas.getContext('2d');

                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                const dataUrl = canvas.toDataURL(FIX_SETTINGS.snapMime, FIX_SETTINGS.snapQuality);
                const success = dataUrl.length > 1000;

                if (success) {
                    thumbnailCreated = true;
                }

                cb(success ? this._snapToObjectUrl(dataUrl) : null);
            };

            const timeupdate = () => {
                snapImage(video, (snap) => {
                    if (snap) {
                        video.removeEventListener('timeupdate', timeupdate);
                        video.pause();

                        resolve(snap);
                    }
                });
            };

            video.addEventListener('loadeddata', () => {
                snapImage(video, (snap) => {
                    if (snap) {
                        video.removeEventListener('timeupdate', timeupdate);

                        resolve(snap);
                    }
                });
            });
            video.addEventListener('error', () => {
                reject('Video failed to load');
            });

            video.addEventListener('timeupdate', timeupdate);
            video.crossOrigin = 'Anonymous';
            video.preload = 'metadata';
            video.src = url;

            // Load video in Safari / IE11
            video.muted = true;
            video.playsInline = true;

            // Support IOS 8.x and below
            video.load();

            // Play the video
            video.play();
        });
    }

    getThumbnailUrl(videoUrl) {
        return videoUrl + '.jpg';
    }

    _videoToObjectUrl(videoFile) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                resolve(this.URL.createObjectURL(new Blob([fileReader.result], {type: videoFile.type})));
            };

            fileReader.onerror = () => {
                reject(`File loading failed ${videoFile}`);
            };

            fileReader.readAsArrayBuffer(videoFile);
        });
    }

    _snapToObjectUrl(dataUrl) {
        const bytes = atob(dataUrl.split(';base64,')[1]),
            buffer = new ArrayBuffer(bytes.length),
            rawData = new Uint8Array(buffer);

        for (let i = 0; i < bytes.length; i++) {
            rawData[i] = bytes.charCodeAt(i);
        }

        return this.URL.createObjectURL(new Blob([rawData], {type: FIX_SETTINGS.snapMime}));
    }
}
