/* eslint-disable consistent-return */
'use strict';
import {UserType} from '@techsee/techsee-common/lib/constants/room.constants';
// @ts-ignore
import COMMON_STATUS_MESSAGES from '@techsee/techsee-common/lib/constants/status-messages.constant';
// @ts-ignore
import {STATUS_MESSAGES, VIEW_PAGES} from './meeting.settings.js';
import {KnownMediaStream} from '@techsee/techsee-media-service/lib/MediaConstants';
import {MeetingStateControllerBase} from './meeting.state.controller.base';
import get from 'lodash/get';
import {SnapshotResult} from '@techsee/techsee-media-service/lib/MediaContracts';
import {TechseeMediaSubscriber} from '@techsee/techsee-media-service/lib/MediaSubscriber';
import {Nullable} from '@techsee/techsee-common';
import {MeetingState} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import {IAudioService} from './AudioService';
import {getMeetingTracer} from './meeting.tracer';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

const trace = getMeetingTracer('MeetingVideoStreamControllerBase');

export abstract class MeetingVideoStreamControllerBase extends MeetingStateControllerBase {
    public VIEW_PAGES: any;

    public runSpeedTestOnClient: boolean;

    public SPEEDTEST_URL = '';

    public messageHistory: any;

    public modalInstance: any;

    public photoSrc: any;

    public isPausedByDashboard = false;

    public isPausedByMobile = false;

    public audioService: IAudioService;

    protected videoSubscriber: Nullable<TechseeMediaSubscriber>;

    // eslint-disable-next-line max-params
    protected constructor(
        public meetingState: MeetingState,
        public $rootScope: any,
        public $scope: any,
        public $sessionStorage: any,
        public $timeout: any,
        public $window: any,
        public $sce: any,
        public tsChatApi: any,
        public tsChatHelper: any,
        public tsTermsAndConditions: any,
        public tsEventService: any,
        public preCameraPermissionService: any,
        public mobileAppMediaService: any,
        db: any,
        public tsFullscreenGalleryService: any,
        audioService: IAudioService,
        public tsEnvironmentDetect: any
    ) {
        super($rootScope, $scope, meetingState, db, tsEventService);

        this.mobileAppMediaService = mobileAppMediaService;
        this.audioService = audioService;

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$window = $window;
        this.tsTermsAndConditions = tsTermsAndConditions;
        this.tsChatApi = tsChatApi;
        this.tsEnvironmentDetect = tsEnvironmentDetect;
        this.$sessionStorage = $sessionStorage;
        this.tsEventService = tsEventService;
        this.preCameraPermissionService = preCameraPermissionService;

        this.VIEW_PAGES = VIEW_PAGES;

        this.runSpeedTestOnClient = this.$sessionStorage.runSpeedTestOnClient;

        this.messageHistory = tsChatHelper.messageHistory;

        this.modalInstance = null;

        this.photoSrc = null;

        this.videoSubscriber = null;

        this.onHistoryMessageReceived = this.onHistoryMessageReceived.bind(this);
        this.onVideoSubscriberChanged = this.onVideoSubscriberChanged.bind(this);
        this._cameraApprovalDialogStateChange = this._cameraApprovalDialogStateChange.bind(this);
    }

    abstract get loopbackStreamType(): KnownMediaStream;

    abstract get currentRole(): string;

    abstract setStreamSource(): void;

    abstract meetingModeHandshakeSuccess(): void;

    abstract syncVideoState(): void;

    abstract initVideoModeHandlers(): void;

    //#region Meeting Pipeline

    get handshakeFailureStatusMessage() {
        return COMMON_STATUS_MESSAGES.VIDEOSTREAM_FAILED;
    }

    meetingModeHandshake() {
        this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_ON_SCREEN_SHARE_TURN_OFF_GUIDANCE_IOS, false);
        this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.SCREEN_SHARE_CAPTURING_APPROVED_IOS, false);

        return this.tsTermsAndConditions
            .syncTOS(this.tsChatApi, this.currentMeetingMode, this.currentRole)
            .then((isApproved: boolean) => {
                if (!isApproved) {
                    return Promise.resolve();
                }

                let cameraApprovalTimeout: any = null;

                trace.info('Waiting for pre camera approval');

                return this.preCameraPermissionService
                    .cameraPreApprove()
                    .then(() => {
                        trace.info('pre camera already approved');
                        this.setStreamSource();
                        this.audioService.setIfAudioEnable();
                        cameraApprovalTimeout = setTimeout(this._cameraApprovalDialogStateChange, 1000);
                        trace.info('before initLocalMediaStreams to create media streams');

                        return this.mobileAppMediaService.initLocalMediaStreams().catch((err: Error) => {
                            if (this.mobileAppMediaService.isVoipEnabled) {
                                this.tsChatApi.setStatus(
                                    socketEvents.CLIENT_OUT_SET_STATUS.AUDIO_HANDSHAKE_SUCCESS,
                                    false
                                );
                            }
                            throw err;
                        });
                    })
                    .then(() => {
                        trace.info('call meetingModeHandshakeSuccess');
                        clearTimeout(cameraApprovalTimeout);
                        this.tsChatApi.setStatus(
                            socketEvents.CLIENT_OUT_SET_STATUS.AUDIO_SUPPORT,
                            this.mobileAppMediaService.isAudioStreamCreated()
                        );

                        // FIXME: TS-15294 - In some IOS devices, after the user is asked to approve the use of the built in camera,
                        // we experienced socket disconnection, causing the session to hangout on the agent side.
                        // The interim solution is to force reconnect by calling _connectInternal() on the chatApi.
                        if (this.tsEnvironmentDetect.isIOS()) {
                            try {
                                this.tsChatApi.connectInternal();
                            } catch (e) {
                                trace.info(`Attempt to reconnect to socket failed: ${e}`);
                            }
                        }

                        this.meetingModeHandshakeSuccess();
                    })
                    .catch((err: any) => {
                        trace.info(
                            'something went wrong in meetingModeHandshake- set inCameraApprovalDialog to false. err:',
                            err
                        );

                        if (this.tsChatApi.client.inCameraApprovalDialog) {
                            this._cameraApprovalDialogStateChange(false);
                        }

                        throw err;
                    })
                    .finally(() => {
                        clearTimeout(cameraApprovalTimeout);
                    });
            });
    }

    syncMeetingState() {
        if (!this.tsChatApi.synced) {
            return;
        }

        if (this.tsChatApi.client.pausedBy === UserType.dashboard) {
            this.isPausedByDashboard = true;
            this._togglePause(true, UserType.dashboard);
        } else if (this.tsChatApi.client.pausedBy === UserType.client) {
            this.isPausedByMobile = true;
            this._togglePause(true, UserType.client);
        }

        this.SPEEDTEST_URL = this.$sce.trustAsResourceUrl(get(this.tsChatApi, 'accountSettings.mobileSpeedtestUrl'));

        this.syncVideoState();
    }

    initModeHandlers() {
        const takeSnapshotActionListener = (options: any) => {
            this.mobileAppMediaService
                .getSnapshotFromKnownStream(KnownMediaStream.USER_VIDEO_STREAM, options)
                .then((snapshotResult: SnapshotResult) => {
                    this.tsChatApi
                        .uploadRoomMedia(snapshotResult.objectUrl, false, null)
                        .then((result: any) =>
                            this.tsChatApi.requestAction(
                                socketEvents.CLIENT_OUT_REQUEST_ACTION.HANDLE_SNAPSHOT,
                                result.mediaUrl
                            )
                        )
                        .catch((err: any) =>
                            this.tsChatApi.requestAction(
                                socketEvents.CLIENT_OUT_REQUEST_ACTION.HANDLE_SNAPSHOT_FAILURE,
                                err
                            )
                        );
                })
                .catch((err: any) =>
                    this.tsChatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.HANDLE_SNAPSHOT_FAILURE, err)
                );
        };
        const toggleVideoListener = (paused: boolean) => this._togglePauseDashboard(paused);
        const runSpeedtestListener = (value: boolean) => this._showSpeedtestPage(value);

        this.tsChatApi.on(socketEvents.CLIENT_IN.NEW_HISTORY_MESSAGE_RECEIVED, this.onHistoryMessageReceived);
        this.tsChatApi.on(socketEvents.CLIENT_IN_CHAT_API.TAKE_SNAPSHOT_ACTION, takeSnapshotActionListener);
        this.tsChatApi.on(socketEvents.CLIENT_IN_CHAT_API.TOGGLE_VIDEO_ACTION, toggleVideoListener);
        this.tsChatApi.on(socketEvents.CLIENT_IN.RUN_SPEED_TEST_ACTION, runSpeedtestListener);

        this.$scope.$on('$destroy', () => {
            this.tsChatApi.off(socketEvents.CLIENT_IN.NEW_HISTORY_MESSAGE_RECEIVED, this.onHistoryMessageReceived);
            this.tsChatApi.off(socketEvents.CLIENT_IN_CHAT_API.TAKE_SNAPSHOT_ACTION, takeSnapshotActionListener);
            this.tsChatApi.off(socketEvents.CLIENT_IN_CHAT_API.TOGGLE_VIDEO_ACTION, toggleVideoListener);
            this.tsChatApi.off(socketEvents.CLIENT_IN.RUN_SPEED_TEST_ACTION, runSpeedtestListener);
        });

        this.initVideoModeHandlers();
    }

    //#endregion Meeting Pipeline

    get isVideoPaused(): boolean {
        return this.isPausedByMobile === true || this.isPausedByDashboard === true;
    }

    get isVideoAvailable(): boolean {
        return this.videoSubscriber !== null && this.videoSubscriber.isPlaying;
    }

    protected _togglePauseDashboard(paused: boolean) {
        this.isPausedByDashboard = paused;

        this._togglePause(paused, UserType.dashboard, true);
    }

    protected _cameraApprovalDialogStateChange(value = true) {
        this.tsChatApi.cameraApprovalDialogStateChange(value);
    }

    protected _showSpeedtestPage(value: boolean) {
        this.runSpeedTestOnClient = true;
        this.$sessionStorage.runSpeedTestOnClient = this.runSpeedTestOnClient;

        if (value) {
            this.tsChatHelper.setPage(VIEW_PAGES.SPEEDTEST);
        }
    }

    protected _togglePause(paused: boolean, side: UserType, sendLog?: boolean) {
        let message = '';

        this.mobileAppMediaService.pauseCameraStream(this.isVideoPaused);

        if (paused) {
            this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.PAUSED_BY, side);
        } else {
            this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.PAUSED_BY, false);
        }

        if (paused) {
            if (side === UserType.dashboard) {
                message = STATUS_MESSAGES.VIDEO_PAUSED_BY_EXPERT;
            } else {
                message = STATUS_MESSAGES.VIDEO_PAUSED_BY_CUSTOMER;
            }
        } else {
            message = STATUS_MESSAGES.VIDEO_RESUMED;
        }

        if (sendLog) {
            this.tsChatApi.sendLog(message);
            this.sendEventLog(message, {
                roomId: this.tsChatApi.roomId,
                meta: {side}
            });
        }
    }

    onHistoryMessageReceived(newHistoryMessageData: any) {
        const {isPartialSet, firstMessageInSet, message} = newHistoryMessageData;

        this.messageHistory = this.tsChatHelper.messageHistory;

        if (isPartialSet && isPartialSet !== this.tsChatHelper.PARTIAL_SET_STATE.COMPLETE) {
            return;
        }

        const isMessageFromClient = get(message, 'sender') === UserType.client;

        if (!message.isNew || isMessageFromClient) {
            return;
        }

        if (!get(this.tsChatApi, 'accountSettings.enableNewVideoOnMobile') && this.modalInstance) {
            // if the modal is already open, we close it and reopen it with the new data
            this.modalInstance.close({reopen: true, newMessageToFocusOn: firstMessageInSet});
        } else {
            this.showGallery(firstMessageInSet);
        }

        if (get(this.tsChatApi, 'accountSettings.enableNewVideoOnMobile')) {
            if (this.tsFullscreenGalleryService.isSupportedMsg(message)) {
                if (isPartialSet === this.tsChatHelper.PARTIAL_SET_STATE.COMPLETE) {
                    return this.tsChatHelper.displayFullscreenGallery(
                        this.tsFullscreenGalleryService.$context.SET_COMPLETED,
                        firstMessageInSet
                    );
                }

                return this.tsChatHelper.displayFullscreenGallery(
                    this.tsFullscreenGalleryService.$context.NEW_MESSAGE_RECEIVED
                );
            }

            // New text message from dashboard
            return this.tsChatHelper._setCurrentPage(VIEW_PAGES.CHAT);
        }
    }

    showGallery(messageToFocusOn: any) {
        this.tsChatApi.sendLog(STATUS_MESSAGES.CUSTOMER_IN_PHOTOGALLERY_PAGE);

        this.sendEventLog(STATUS_MESSAGES.CUSTOMER_IN_PHOTOGALLERY_PAGE, {
            roomId: this.tsChatApi.roomId
        });

        if (!get(this.tsChatApi, 'accountSettings.enableNewVideoOnMobile')) {
            this.modalInstance = this.tsChatHelper.showGallery(this.messageHistory, messageToFocusOn);

            if (this.modalInstance) {
                this.modalInstance.result
                    .then(({reopen, newMessageToFocusOn}: {reopen: boolean; newMessageToFocusOn: any}) => {
                        // if we want to refresh the modal we manually close and open it
                        this.modalInstance = null;
                        if (reopen) {
                            this.showGallery(newMessageToFocusOn);
                        }
                    })
                    .catch(() => {
                        this.modalInstance = null;

                        this.tsChatApi.sendLog(STATUS_MESSAGES.CUSTOMER_CLOSED_PHOTOGALLERY_PAGE);
                        this.sendEventLog(STATUS_MESSAGES.CUSTOMER_CLOSED_PHOTOGALLERY_PAGE, {
                            roomId: this.tsChatApi.roomId
                        });
                    });
            }
        }
    }

    togglePauseMobile() {
        this.isPausedByMobile = !this.isPausedByMobile;

        this._togglePause(this.isPausedByMobile, UserType.client, true);
    }

    onVideoSubscriberChanged(videoSubscriber: TechseeMediaSubscriber) {
        this.videoSubscriber = videoSubscriber;
        this.$rootScope.safeApply();
    }
}
