'use strict';

import {STATUS_MESSAGES} from '../../states/meeting/meeting.settings';
import startsWith from 'lodash/startsWith';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';

// Service that makes sure that all errors are logged
export class TsExceptionHandler {
    constructor($window, db, tsChatApi, tsEventService) {
        'ngInject';

        this.$window = $window;
        this.db = db;
        this.chatApi = tsChatApi;
        this.EventService = tsEventService;
    }

    init() {
        this.$window.onerror = (msg, src, line, col) => {
            if (startsWith(msg, 'Uncaught Error: [$rootScope:infdig]')) {
                return true;
            }

            const details = {
                clientType: PlatformType.mobile_web,
                error: {msg, src, line, col}
            };

            let roomId = 'none';

            if (this.chatApi && this.chatApi.roomId) {
                roomId = this.chatApi.roomId;
            }

            return this.EventService.sendEventLog('none', roomId, STATUS_MESSAGES.UNCAUGHT_EXCEPTION, details);
        };
    }
}
