'use strict';

import isNumber from 'lodash/isNumber';

import EXIF from '../../../js/exif';
import exifOrient from 'exif-orient/exif-orient';
import {FIX_SETTINGS} from './ts-image-fixer.settings.js';

/**
 * Utility service that wraps the functionality required to re-orient and
 * resize an image
 */
export class TsImageFixerService {
    constructor($window, tsChatApi, tsEnvironmentDetect) {
        'ngInject';

        const osVersion = tsEnvironmentDetect.isIOS() && tsEnvironmentDetect.os();

        this.isIosAndOver13 = osVersion && osVersion.version && parseInt(osVersion.version, 10) >= 13;

        this.$window = $window;

        this.EXIF = EXIF;
        this.exifOrient = exifOrient;
        this.chatApi = tsChatApi;

        this.settings = FIX_SETTINGS;
    }

    get isHighImageQuality() {
        return this.chatApi.enableSharing && this.chatApi.imageSharingQuality === 1;
    }

    /**
     * Re-orient and image file, according to exifdata and subsample
     * if it's larger than 1 megapixel
     *
     * @param imageFile - file received from an input tag
     * @return Promise - resolves with the ObjectUrl of the fixed image
     */
    fix(imageFile) {
        return new Promise((resolve, reject) => {
            try {
                const fixStarted = this.EXIF.getData(imageFile, () => {
                    const orientation = imageFile.exifdata.Orientation;

                    this._readToImage(imageFile)
                        .then((image) => {
                            const dataUrl = this._resizeCanvas(image);

                            return this._rotate(dataUrl, orientation);
                        })
                        .then((canvas) => resolve(this._canvasToObjectUrl(canvas)))
                        .catch((err) => reject(err));
                });

                // Invalid image input
                if (!fixStarted) {
                    reject('Image fix failed: failed to retrieve EXIF data');
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    _dataUrlToImage(dataUrl) {
        return new Promise((resolve, reject) => {
            const img = new Image();

            img.onload = () => resolve(img);
            img.onerror = (err) => reject(err);
            img.src = dataUrl;
        });
    }

    fixDataUrl(dataUrl, useHighQuality) {
        return this._dataUrlToImage(dataUrl).then((image) => {
            const resizeRequirement = this._isResizeRequired(image, useHighQuality);

            if (resizeRequirement.resized) {
                return this._resizeCanvas(image, resizeRequirement);
            }

            return dataUrl;
        });
    }

    /**
     * Takes a file from and html input and reads it to an Image
     *
     * @param imageFile - file to read
     * @return Promise - resolves with newly created Image
     */
    _readToImage(imageFile) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader(),
                image = new Image();

            image.onload = () => {
                resolve(image);
            };

            image.onerror = () => {
                reject('Image failed to load');
            };

            reader.onloadend = () => {
                image.src = reader.result;
            };

            reader.readAsDataURL(imageFile);
        });
    }

    /**
     * Fixes image rotation
     *
     * @param image - Image to rotate
     * @param exifOrientation - exif orientation
     * @return Promise - resolves with a canvas (created from exif-orient) that
     *                   has the rotated image
     */
    _rotate(image, exifOrientation) {
        return new Promise((resolve, reject) => {
            let orientation = exifOrientation;

            // need to set orientation = 1 when:
            // ios >= 13 AND orientation = (6 OR 3)
            const setOrientation = this.isIosAndOver13 && (orientation === 3 || orientation === 6);

            // Needed for files without exifdata. Even when there's no rotation
            // needed or applicable (no exif) we still use exifOrient, to create the
            // canvas that will be used in later transformations
            if (!isNumber(orientation) || orientation <= 0 || setOrientation) {
                orientation = 1;
            }

            this.exifOrient(image, orientation, (err, canvas) => {
                if (err) {
                    return reject(err);
                }

                return resolve(canvas);
            });
        });
    }

    _isResizeRequired(image, useHighQuality) {
        const maxImageSize =
            this.isHighImageQuality || useHighQuality
                ? FIX_SETTINGS.highQualityImageSizeThreshold
                : FIX_SETTINGS.imageSizeThreshold;

        if (image.naturalWidth * image.naturalHeight > maxImageSize) {
            const ratio = image.naturalWidth / image.naturalHeight,
                newWidth = Math.sqrt(maxImageSize * ratio),
                newHeight = newWidth / ratio;

            return {resized: true, width: newWidth, height: newHeight};
        }

        return {width: image.naturalWidth, height: image.naturalHeight};
    }

    /**
     * Resizes an image in a canvas, if it's over a threshold defined in the settings
     *
     * @param image - image to resize
     * @param size - override resize dimensions (optional)
     * @return resized image dataUrl
     */
    _resizeCanvas(image, size) {
        const canvas = angular.element('<canvas />')[0],
            ctx = canvas.getContext('2d');

        const {width, height} = size || this._isResizeRequired(image);

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        return canvas.toDataURL(FIX_SETTINGS.defaultMime, FIX_SETTINGS.imageQuality);
    }

    /**
     * Converts a dataURL that we obtain from the canvas, to an ObjectURL
     * that we can feed to the chat api service
     *
     * @param canvas - canvas to convert
     * @returns ObjectURL - ObjectURL of the image
     */
    _canvasToObjectUrl(canvas) {
        const dataUrl = canvas.toDataURL(FIX_SETTINGS.defaultMime, FIX_SETTINGS.imageQuality),
            URL = this.$window.URL,
            bytes = atob(dataUrl.split(';base64,')[1]),
            buffer = new ArrayBuffer(bytes.length),
            rawData = new Uint8Array(buffer);

        for (let i = 0; i < bytes.length; i++) {
            rawData[i] = bytes.charCodeAt(i);
        }

        return URL.createObjectURL(new Blob([rawData], {type: FIX_SETTINGS.defaultMime}));
    }
}
