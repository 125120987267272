import SurveyController from './components/survey/controller';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface IEndStores {
    [name: string]: object;
}

export class EndStores {
    stores?: IEndStores;

    private _rootScope: any;
    private _stateParams: any;
    private _tsStateHelper: any;
    private _translate: any;
    private _db: any;
    private _tsChatApi: any;
    private _tsTermsAndConditions: any;
    private _tsEnvironmentDetect: any;
    private _mobileBranding: Record<string, string>;

    //We use this type of injection, because ng-annotate-loader not runs on TypeScript files.
    static $inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        'tsStateHelper',
        '$translate',
        '$localStorage',
        'db',
        'tsChatApi',
        'tsTermsAndConditions',
        'tsEnvironmentDetect'
    ];

    constructor(
        $scope: any,
        $rootScope: any,
        $stateParams: any,
        tsStateHelper: any,
        $translate: any,
        $localStorage: any,
        db: any,
        tsChatApi: any,
        tsTermsAndConditions: any,
        tsEnvironmentDetect: any
    ) {
        this._rootScope = $rootScope;
        this._stateParams = $stateParams;
        this._tsStateHelper = tsStateHelper;
        this._translate = $translate;
        this._db = db;
        this._tsChatApi = tsChatApi;
        this._tsTermsAndConditions = tsTermsAndConditions;
        this._tsEnvironmentDetect = tsEnvironmentDetect;
        this._mobileBranding = $localStorage.mobileBranding || {};

        this.initStores().then((stores) => {
            $scope.$apply(() => (this.stores = stores));
        });
    }

    private initTranslate(): Promise<any> {
        return new Promise((resolve) => {
            this._translate.onReady(resolve);
        });
    }

    private initStores(): PromiseLike<IEndStores> {
        return new Promise((resolve) => {
            this.initTranslate().then(() => {
                const stores: IEndStores = {};

                const surveyController = new SurveyController(
                    this._rootScope,
                    this._stateParams,
                    this._tsStateHelper,
                    this._translate,
                    this._db,
                    this._tsChatApi,
                    this._tsTermsAndConditions
                );

                defineStore('surveyController', surveyController);
                defineStore('translate', this._translate.instant);
                defineStore('requireImage', this._rootScope.requireImage);
                defineStore('tsEnvironmentDetect', this._tsEnvironmentDetect);
                defineStore('stateParams', this._stateParams);
                defineStore('brandingData', this._mobileBranding);
                resolve(stores);

                /*
                 Because of technical issue with Angular digest loop,
                 We define stores as non enumerable properties. This helps to avoid infinite digest of angular.
                */
                function defineStore(storeName: string, value: any) {
                    Object.defineProperty(stores, storeName, {
                        value: value,
                        enumerable: false
                    });
                }
            });
        });
    }
}
