'use strict';

import './end-meeting-confirmation.style.scss';
import endMeetingConfirmationView from './end-meeting-confirmation.view.html';
import EndMeetingConfirmationController from './end-meeting-confirmation.controller';

import {STATUS_MESSAGES} from '../../meeting.settings.js';

export class EndMeetingConfirmationModal {
    constructor($uibModal, db, tsChatApi, tsEventService) {
        'ngInject';

        this.$uibModal = $uibModal;
        this.chatApi = tsChatApi;
        this.db = db;
        this.instance = null;
        this.EventService = tsEventService;
    }

    show(newStyle) {
        this.instance = this.$uibModal.open({
            // fixes backdrop not disappearing https://github.com/angular-ui/bootstrap/issues/3633
            animation: false,
            backdrop: 'static',
            template: endMeetingConfirmationView,
            controller: EndMeetingConfirmationController,
            controllerAs: 'vm',
            windowClass: 'end-meeting-modal',
            backdropClass: 'end-meeting-backdrop',
            resolve: {
                newStyle: newStyle
            }
        });

        this.instance.result.catch(() => {
            this.instance = null;
            this.chatApi.sendLog(STATUS_MESSAGES.END_MEETING_CANCELLED, new Date().getTime());
            this.EventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                STATUS_MESSAGES.END_MEETING_CANCELLED,
                'Customer selected to continue the session'
            );
        });

        return this.instance.result;
    }
}
