'use strict';

import assign from 'lodash/assign';

// Translation strings
import englishTranslations from '../../../i18n/en-us.i18n.json';
import hebrewTranslations from '../../../i18n/he-il.i18n.json';
import arabicTranslations from '../../../i18n/ar-ar.i18n.json';
import russianTranslations from '../../../i18n/ru-ru.i18n.json';
import greekTranslations from '../../../i18n/el-gr.i18n.json';
import spanishTranslations from '../../../i18n/es-es.i18n.json';
import dutchTranslations from '../../../i18n/nl-nl.i18n.json';
import frenchTranslations from '../../../i18n/fr-fr.i18n.json';
import italianTranslations from '../../../i18n/it-it.i18n.json';
import polishTranslations from '../../../i18n/pl-pl.i18n.json';
import portugueseTranslations from '../../../i18n/pt-pt.i18n.json';
import germanTranslations from '../../../i18n/de-de.i18n.json';
import japaneseTranslations from '../../../i18n/jp-jp.i18n.json';
import turkishTranslations from '../../../i18n/tr-tr.i18n.json';
import swedishTranslation from '../../../i18n/sv-se.i18n.json';
import koreanTranslation from '../../../i18n/ko-kr.i18n.json';
import slovakTranslation from '../../../i18n/sk-sk.i18n.json';
import thaiTranslation from '../../../i18n/th-th.i18n.json';
import hungarianTranslation from '../../../i18n/hu-hu.i18n.json';
import lithuanianTranslations from '../../../i18n/lt-lt.i18n.json';
import albanianTranslations from '../../../i18n/sq-al.i18n.json';
import czechTranslations from '../../../i18n/cz-cz.i18n.json';
import canadianFrenchTranslations from '../../../i18n/fr-ca.i18n.json';
import danishTranslations from '../../../i18n/da-dk.i18n.json';
import norwegianTranslations from '../../../i18n/nv-no.i18n.json';
import finnishTranslations from '../../../i18n/fi-fi.i18n.json';
import bulgarianTranslations from '../../../i18n/bg-bg.i18n.json';
import croatianTranslations from '../../../i18n/hr-hr.i18n.json';
import romanianTranslations from '../../../i18n/ro-ro.i18n.json';
import slovenianTranslations from '../../../i18n/sl-si.i18n.json';
import ukrainianTranslations from '../../../i18n/uk-ua.i18n.json';
import indonesianTranslations from '../../../i18n/id-id.i18n.json';
import vietnameseTranslations from '../../../i18n/vi-vn.i18n.json';
import chineseTranslations from '../../../i18n/zh-cn.i18n.json';

export class CustomTranslateLoader {
    constructor(tsTranslationHelper) {
        'ngInject';

        const locales = {
            en_US: englishTranslations,
            he_IL: hebrewTranslations,
            ar_AR: arabicTranslations,
            ru_RU: russianTranslations,
            el_GR: greekTranslations,
            es_ES: spanishTranslations,
            nl_NL: dutchTranslations,
            fr_FR: frenchTranslations,
            it_IT: italianTranslations,
            pt_PT: portugueseTranslations,
            de_DE: germanTranslations,
            jp_JP: japaneseTranslations,
            tr_TR: turkishTranslations,
            sv_SE: swedishTranslation,
            pl_PL: polishTranslations,
            ko_KR: koreanTranslation,
            sk_SK: slovakTranslation,
            th_TH: thaiTranslation,
            hu_HU: hungarianTranslation,
            lt_LT: lithuanianTranslations,
            sq_AL: albanianTranslations,
            cz_CZ: czechTranslations,
            fr_CA: canadianFrenchTranslations,
            da_DK: danishTranslations,
            nv_NO: norwegianTranslations,
            fi_FI: finnishTranslations,
            bg_BG: bulgarianTranslations,
            hr_HR: croatianTranslations,
            ro_RO: romanianTranslations,
            sl_SI: slovenianTranslations,
            uk_UA: ukrainianTranslations,
            id_ID: indonesianTranslations,
            zh_CN: chineseTranslations,
            vi_VN: vietnameseTranslations
        };

        return (options) => {
            const customizedTranslation = assign(locales[options.key], tsTranslationHelper.getAccountCustomStrings());

            return Promise.resolve(customizedTranslation);
        };
    }
}
