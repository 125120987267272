import {action, computed, observable} from 'mobx';
import {Nullable} from '@techsee/techsee-common';
import {
    IMultipartyController,
    MultipartyConnectFailedEvent,
    MultipartyConnectionCreatedEvent,
    MultipartyConnectionDestroyedEvent,
    MultipartyEvents,
    MultipartyMainVideoMirrorStateChangedEvent,
    MultipartyMainVideoPinnedAndStreamingStateChangedEvent,
    MultipartyStreamCreatedEvent,
    MultipartyStreamDestroyedEvent,
    MultipartyStreamPropertyChangedEvent,
    OnEndMeetingRequestedCb,
    OnPermissionsModalVisibilityChangedCb,
    OnPinnedVideoChangedCb,
    PermissionsModalVisibilityChangedEvent,
    PinnedUserType,
    DesktopSharingEvent,
    MultipartyServiceVideoElementCreatedEvent
} from '@techsee/techsee-media-service-client';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {ITranslate} from '../../../../services/LocalizationService';
import {MagicMarkerController} from './MagicMarkerController';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {toSessionClientRole} from '@techsee/techsee-media-service-client/lib/services/types';
import {VideoLayoutType} from '@techsee/techsee-common/lib/constants/media.constants';
import {SessionClientRole, VideoTypesEnum} from '@techsee/techsee-media-service/lib/MediaConstants';
import {screenSharedBy} from '@techsee/techsee-common/lib/constants/account.constants';
import {RemoteController} from '../../remote.controller';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

type SendEventLogFn = (eventType: string, meta?: Record<string, unknown>) => Promise<void>;

export interface IVideoController {
    isPeerMediaPermissionRejected: boolean;
    multipartyController: Nullable<IMultipartyController>;
    translate: ITranslate;
    setMultipartyController: (multipartyController: IMultipartyController) => void;
    setPinnedVideo: OnPinnedVideoChangedCb;
    setIsPeerMediaPermissionsRejected: (isPeerMediaPermissionRejected: boolean) => void;
    onPermissionsModalVisibilityChanged: OnPermissionsModalVisibilityChangedCb;
    videoLayoutType: VideoLayoutType;
    onEndMeetingRequested?: OnEndMeetingRequestedCb;
    magicMarkerController: MagicMarkerController;
    isAgentClickOnScreenShare: boolean;
    remoteController?: RemoteController;
    isMobile?: boolean;
    setIsPortrait: (isPortrait: boolean) => void;
}

export class VideoController implements IVideoController {
    @observable private _multipartyController: Nullable<IMultipartyController> = null;
    @observable private _isPeerMediaPermissionRejected: boolean = false;
    @observable private _isAgentClickOnScreenShare: boolean = false;
    @observable private _remoteController?: RemoteController;
    @observable private _isMobile: boolean = false;
    @observable private _isPortrait = false;
    @observable private _isFullScreen = false;

    public readonly magicMarkerController: MagicMarkerController;

    @observable private localVideoDimensions: {width: number; height: number} = {width: 640, height: 480};
    @observable private remoteVideoDimensions: {width: number; height: number} = {width: 640, height: 480};

    constructor(
        private readonly db: any,
        private readonly roomId: string,
        private readonly tsChatApi: any,
        public readonly translate: ITranslate,
        private readonly sendEventLog: SendEventLogFn,
        private readonly streamEstablishCb: (event?: any) => void,
        public readonly onExitMeetingRequested: OnEndMeetingRequestedCb,
        environmentService: ITsEnvironmentDetect
    ) {
        this.magicMarkerController = new MagicMarkerController(
            this.tsChatApi,
            environmentService.isMobile(),
            sendEventLog
        );

        this._isMobile = environmentService.isMobile();

        this.tsChatApi.on(
            socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_CLICK_ON_DESKTOP_SHARE,
            (data: string, value: boolean) => {
                this.setAgentClickOnScreenShare(value);
            }
        );

        this.tsChatApi.on(socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_IS_FULL_SCREEN, (data: string, value: boolean) => {
            this.magicMarkerController.setIsFullScreen(value);
        });

        this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.CLICK_ON_DESKTOP_SHARE, false);
        this.db.Rooms.find(this.roomId).then((room: any) => {
            if (!room.livePointerCode) {
                return;
            }

            this._remoteController = new RemoteController(
                tsChatApi,
                environmentService,
                db.Rooms.setReportedField,
                room.livePointerCode || ''
            );
        });
    }

    public onPermissionsModalVisibilityChanged = (eventType: PermissionsModalVisibilityChangedEvent) => {
        switch (eventType) {
            case PermissionsModalVisibilityChangedEvent.MODAL_OPEN:
                this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_VIEWING_PERMISSIONS_MODAL, true);
                this.sendEventLog(LOG_EVENTS.mediaPermissionModalOpened);
                break;
            case PermissionsModalVisibilityChangedEvent.MODAL_CLOSED:
                this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_VIEWING_PERMISSIONS_MODAL, false);
                this.sendEventLog(LOG_EVENTS.mediaPermissionModalClosed);
                break;
            default:
                break;
        }
    };

    @computed
    get isPeerMediaPermissionRejected() {
        return this._isPeerMediaPermissionRejected;
    }

    @computed
    get multipartyController() {
        return this._multipartyController;
    }

    @computed
    get remoteController() {
        return this._remoteController;
    }

    @computed
    get isMobile() {
        return this._isMobile;
    }

    @computed
    get videoLayoutType() {
        return this.tsChatApi.accountSettings.faceMeetVideoLayout || VideoLayoutType.AgentPinnedLayout;
    }

    @computed
    get isAgentClickOnScreenShare() {
        return this._isAgentClickOnScreenShare;
    }

    @action
    setAgentClickOnScreenShare = (value: boolean) => {
        this._isAgentClickOnScreenShare = value;
    };

    @action
    public setIsPortrait = (isPortrait: boolean) => {
        this._isPortrait = isPortrait;

        this.onStreamingStateChanged();
    };

    public onStreamingStateChanged(): void {
        this.magicMarkerController.setIsFullScreen((this._isFullScreen as boolean) && this._isPortrait);

        this.tsChatApi.setStatus(
            socketEvents.CLIENT_OUT_SET_STATUS.IS_FULLSCREEN,
            this._isFullScreen && this._isPortrait
        );
    }

    public setPinnedVideo = (pinnedVideo: {pinnedUserRole: PinnedUserType; videoType: VideoTypesEnum}) => {
        this._multipartyController?.setPinnedVideo(pinnedVideo);
        this.magicMarkerController.clearRemoteDrawings();
        this.magicMarkerController.setEnabled(pinnedVideo.pinnedUserRole !== PinnedUserType.NONE);

        const connection = this._multipartyController?.getMainConnection();

        this.magicMarkerController.setMirrored(!!connection?.mirrored);
    };

    @action
    public setMultipartyController = (multipartyController: IMultipartyController) => {
        this._multipartyController = multipartyController;

        this.initializeHandlers();
        this.logEvents();
    };

    @action
    public setIsPeerMediaPermissionsRejected = (mediaPermissionRejected: boolean): void => {
        this._isPeerMediaPermissionRejected = mediaPermissionRejected;
    };

    @action
    public onEndMeetingRequested = () => {
        this.onExitMeetingRequested();
    };

    private logEvents = () => {
        this._multipartyController!.on(
            MultipartyEvents.streamPropertyChanged,
            (event: MultipartyStreamPropertyChangedEvent) => {
                this.sendEventLog(LOG_EVENTS.opentok, event);
            }
        );
        this._multipartyController!.on(MultipartyEvents.connectFailed, (event: MultipartyConnectFailedEvent) => {
            this.sendEventLog(LOG_EVENTS.opentokError.type, event.meta);
        });

        this._multipartyController!.on(
            MultipartyEvents.connectionCreated,
            (event: MultipartyConnectionCreatedEvent) => {
                this.sendEventLog(LOG_EVENTS.opentokSessionCreated, event);
            }
        );

        this._multipartyController!.on(
            MultipartyEvents.connectionDestroyed,
            (event: MultipartyConnectionDestroyedEvent) => {
                this.sendEventLog(LOG_EVENTS.opentok, event);
            }
        );

        this._multipartyController!.on(MultipartyEvents.streamDestroyed, (event: MultipartyStreamDestroyedEvent) => {
            this.magicMarkerController.setVideoDimensions(
                this._multipartyController?.getPinnedVideo().pinnedUserRole === PinnedUserType.AGENT
                    ? this.localVideoDimensions
                    : this.remoteVideoDimensions
            );

            this.sendEventLog(LOG_EVENTS.opentok, event);
        });

        this._multipartyController!.on(MultipartyEvents.streamCreated, (event: MultipartyStreamCreatedEvent) => {
            this.sendEventLog(LOG_EVENTS.opentok, event);
        });

        this._multipartyController!.on(MultipartyEvents.mediaDeviceAccessDenied, () => {
            this.sendEventLog(LOG_EVENTS.opentokError.type);
        });

        this._multipartyController?.on(MultipartyEvents.updateTrafficLogs, (meta: any) => {
            this.updateTrafficLogs(meta.videoTrafficDelta, meta.videoDurationDelta);
        });
    };

    private initializeHandlers = () => {
        this._multipartyController?.on(MultipartyEvents.streamCreated, (e: MultipartyStreamCreatedEvent) => {
            if (e.isOwnConnection) {
                this.streamEstablishCb();
            }

            if (e.clientRole === toSessionClientRole(this._multipartyController?.getPinnedVideo().pinnedUserRole)) {
                this.magicMarkerController.setMirrored(e.mirror);
            }
        });

        this._multipartyController!.on(MultipartyEvents.mediaDeviceAccessDenied, () => {
            this.streamEstablishCb({mediaDeviceAccessDenied: true});
        });

        this._multipartyController?.on(
            MultipartyEvents.videoElementCreated,
            (e: MultipartyServiceVideoElementCreatedEvent) => {
                // @ts-ignore
                const streamScreenType = e.element?.srcObject.getVideoTracks()[0].getSettings().displaySurface;

                if (streamScreenType) {
                    const data = {
                        event: {
                            key: 'desktopSharingStatus',
                            value: {type: 'desktopSharingStarted', shareBy: screenSharedBy.Client, streamScreenType},
                            type: 'push'
                        }
                    };

                    this.db.Rooms.setReportedField(this.roomId, {data});
                }
            }
        );

        this._multipartyController?.on(
            MultipartyEvents.streamPropertyChanged,
            (e: MultipartyStreamPropertyChangedEvent) => {
                if (
                    e.clientRole === toSessionClientRole(this._multipartyController?.getPinnedVideo().pinnedUserRole) &&
                    // @ts-ignore
                    e?.videoType === this._multipartyController.getPinnedVideo().videoType
                ) {
                    return this.updateStreamVideoDimensions(e.isOwnConnection, e.videoDimensions);
                }
            }
        );

        this._multipartyController!.on(
            MultipartyEvents.mainVideoMirrorStateChanged,
            (event: MultipartyMainVideoMirrorStateChangedEvent) => {
                this.magicMarkerController.clearRemoteDrawings();
                this.magicMarkerController.setMirrored(event.mirror);
            }
        );

        this._multipartyController!.on(MultipartyEvents.desktopSharing, (event: DesktopSharingEvent) => {
            if (event.userRole !== SessionClientRole.USER) {
                return;
            }

            if (event.clickOnDesktopShare) {
                this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.CLICK_ON_DESKTOP_SHARE, true);
                this.sendEventLog(LOG_EVENTS.clickOnDesktopShare);
            }

            if (event.desktopSharing) {
                this.sendEventLog(LOG_EVENTS.clientDesktopSharing);
            }

            if (event.clickOnStopDesktopShare) {
                this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.CLICK_ON_DESKTOP_SHARE, false);

                this.sendEventLog(LOG_EVENTS.clickOnStopDesktopShare);
            }

            if (event.failedToDesktopShare) {
                this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.CLICK_ON_DESKTOP_SHARE, false);

                this.sendEventLog(LOG_EVENTS.failedToDesktopShare, {err: event?.err});
            }
        });

        this._multipartyController!.on(
            MultipartyEvents.mainVideoPinnedAndStreamingStateChanged,
            (event: MultipartyMainVideoPinnedAndStreamingStateChangedEvent) => {
                if (typeof event.isFullScreen === 'boolean') {
                    this._isFullScreen = event.isFullScreen;
                    this.onStreamingStateChanged();
                }

                const savedDimensions =
                    this._multipartyController?.getPinnedVideo().pinnedUserRole === PinnedUserType.AGENT
                        ? this.localVideoDimensions
                        : this.remoteVideoDimensions;

                this.magicMarkerController.clearRemoteDrawings();
                this.magicMarkerController.setEnabled(event.isPinnedAndStreaming);
                this.magicMarkerController.setVideoDimensions(
                    event.videoDimensions ? event.videoDimensions : savedDimensions
                );
            }
        );
    };

    @action
    private updateStreamVideoDimensions(
        isOwnConnection: boolean,
        videoDimensions: {
            width: number;
            height: number;
        }
    ) {
        if (isOwnConnection) {
            /** @FIXME This state should not be saved here, it should be exposed on multiPartyController */
            this.localVideoDimensions = videoDimensions;
        } else {
            this.remoteVideoDimensions = videoDimensions;
        }

        if (this._multipartyController?.getPinnedVideo().pinnedUserRole !== PinnedUserType.NONE) {
            this.magicMarkerController.clearRemoteDrawings();
            this.magicMarkerController.setVideoDimensions(
                this._multipartyController?.getPinnedVideo().pinnedUserRole === PinnedUserType.USER
                    ? this.localVideoDimensions
                    : this.remoteVideoDimensions
            );
        }
    }

    private updateTrafficLogs = (videoTrafficDelta: any, videoDurationDelta: any) => {
        this.db.Rooms.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'dashboardVideoTraffic',
                    value: videoTrafficDelta,
                    type: 'inc'
                }
            }
        });

        this.db.Rooms.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'dashboardVideoDuration',
                    value: videoDurationDelta,
                    type: 'inc'
                }
            }
        });
    };
}
