'use strict';

import forEach from 'lodash/forEach';

export interface IVisibilityChange {
    visible(callback: (...args: any[]) => void): void;
    hidden(callback: (...args: any[]) => void): void;
    isVisible(): boolean;
    isDisabled(): boolean;
    enableVisibilityEvents(): void;
    disableVisibilityEvents(): void;
    on(event: string, listener: (...args: any[]) => void): void;
    off(event: string, listener: (...args: any[]) => void): void;
}

/**
 * Service that abstracts the browser-specific differences in the
 * visibilitychange event API and allows for listening to it
 */
export class TsVisibilityChange {
    private window: Window;
    private _callbacks: {visible: any[]; hidden: any[]};
    private hiddenAttr = '';
    private visibilitychange = '';
    private visibilityChangeHandler: () => void = () => undefined;
    private _visibilityEventsDisabled = false;

    constructor(window: any) {
        'ngInject';
        this.window = window;
        // visibilitychange is one event, but we want to expose an API
        // that handles it as two: visible and hidden, so we maintain
        // separate callback registries for the off method to avoid
        // de-registering a function that listens to 'visible' when it is
        // de-registered from 'hidden' and vice-versa
        this._callbacks = {
            visible: [],
            hidden: []
        };

        this._init();
        this._addHandlers();
    }

    _init() {
        const doc: any = this.window.document;

        if (typeof doc.hidden !== 'undefined') {
            this.hiddenAttr = 'hidden';
            this.visibilitychange = 'visibilitychange';
        } else if (typeof doc.mozhidden !== 'undefined') {
            this.hiddenAttr = 'mozhidden';
            this.visibilitychange = 'mozvisibilitychange';
        } else if (typeof doc.mshidden !== 'undefined') {
            this.hiddenAttr = 'mshidden';
            this.visibilitychange = 'msvisibilitychange';
        } else if (typeof doc.webkithidden !== 'undefined') {
            this.hiddenAttr = 'webkithidden';
            this.visibilitychange = 'webkitvisibilitychange';
        }
    }

    _addHandlers() {
        if (!this.isSupported()) {
            return;
        }

        const doc = this.window.document;

        this.visibilityChangeHandler = () => {
            // @ts-ignore
            if (doc[this.hiddenAttr]) {
                forEach(this._callbacks.hidden, (cb) => cb());
            } else {
                forEach(this._callbacks.visible, (cb) => cb());
            }
        };

        doc.addEventListener(this.visibilitychange, this.visibilityChangeHandler);
    }

    isVisible() {
        // @ts-ignore
        return !this.window.document[this.hiddenAttr];
    }

    isSupported() {
        return !!(this.hiddenAttr && this.visibilitychange);
    }

    enableVisibilityEvents() {
        this._visibilityEventsDisabled = false;
    }

    disableVisibilityEvents() {
        this._visibilityEventsDisabled = true;
    }

    isDisabled() {
        return this._visibilityEventsDisabled;
    }

    visible(callback: (...args: any[]) => void) {
        this.on('visible', callback);
    }

    hidden(callback: (...args: any[]) => void) {
        this.on('hidden', callback);
    }

    /*
     * Attach a handler to a visibility change event
     *
     * @param eventName - {String} 'visible' or 'hidden'
     * @param callback - {Function} callback
     */
    on(eventName: string, callback: (...args: any[]) => void) {
        if (!this.isSupported() || ['hidden', 'visible'].indexOf(eventName) === -1) {
            return;
        }

        // @ts-ignore
        this._callbacks[eventName] = this._callbacks[eventName].concat(callback);
    }

    /*
     * Detach a handler from a visibility change event
     *
     * @param eventName - {String} 'visible' or 'hidden'
     * @param callback - {Function} callback
     */
    off(eventName: string, callback: (...args: any[]) => void) {
        if (!this.isSupported() || ['hidden', 'visible'].indexOf(eventName) === -1) {
            return;
        }

        // @ts-ignore
        const index = this._callbacks[eventName].indexOf(callback);

        if (index >= 0) {
            // @ts-ignore
            const callbacks = this._callbacks[eventName];

            // @ts-ignore
            this._callbacks[eventName] = callbacks.slice(0, index).concat(callbacks.slice(index + 1));
        }
    }

    removeAllListeners() {
        this._callbacks = {
            visible: [],
            hidden: []
        };
    }
}
