'use strict';

import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import forEach from 'lodash/forEach';

export class TsUrlUtilsService {
    constructor($rootScope, $location, $window) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$window = $window;

        this.urlChangeListeners = [];
        this.queryChangeListeners = {};

        this.urlChangeFlag = false;
        this.evtPrefix = 'ts-url-utils-evt.';
    }

    /**
     * Setup a listener on URL changes
     */
    _urlChangeHandler() {
        this.urlChangeFlag = true;

        // On URL changes, execute registered listeners
        this.$rootScope.$on('$locationChangeSuccess', (e, newUrl, oldUrl, newState, oldState) => {
            forEach(this.urlChangeListeners, (listener) => {
                listener(e, newUrl, oldUrl, newState, oldState);
            });
        });
    }

    /**
     * Register a callback function to be called on URL changes
     *
     * @param  {Function} cb callback
     */
    onUrlChange(cb) {
        // Validate
        if (!isFunction(cb)) {
            throw new TypeError('`onUrlChange`: `cb` must be a function');
        }

        this.urlChangeListeners.push(cb);

        if (!this.urlChangeFlag) {
            this._urlChangeHandler();
        }
    }

    /**
     * Register a callback function to be called on a specific query param value changes
     * The listeners themselves will be destroyed once the given scope is destroyed by default
     *
     * @param  {Object}   scope The Angular scope that initiated the listener
     * @param  {string}   param The query param key to listen for changes on
     * @param  {Function} cb    Callback to execute with the new param value
     */
    onQueryChange(scope, param, cb) {
        // Validate
        if (!isObject(scope) || !isString(param) || !isFunction(cb)) {
            throw new TypeError('`onQueryChange`: Wrong arguments types');
        }

        // Subscribe to given param change events
        scope.$on(this.evtPrefix + param, (e, paramValue) => {
            cb(paramValue);
        });

        let listener = this.queryChangeListeners[param];

        // If the listener for this query param already exists, stop here
        if (listener) {
            return;
        }

        // Register a new listener for this query param
        this.queryChangeListeners[param] = listener = {};
        // Set initial value, to check later if it changed
        listener.currValue = this.getParamValue(param);

        // Listen to URL changes to check if the query param changed
        this.onUrlChange(() => {
            const paramValue = this.getParamValue(param);

            // Stop if the query param value didn't change
            if (listener.currValue === paramValue) {
                return;
            }

            listener.currValue = paramValue;

            // Notify all subscribers of the param change
            this.$rootScope.$broadcast(this.evtPrefix + param, paramValue);
        });
    }

    /**
     * Get the value of a specific query param
     *
     * @param  {string} param The query param to look for
     * @return {string}       The param value
     */
    getParamValue(param) {
        const queryParams = this.$location.search();

        return queryParams[param] || this.getSearchParamValue(param);
    }

    /**
     * Get the value of pre-hash params (required on android
     * intents, where a hash is not an allowed character)
     *
     * @param  {string} param The query param to look for
     * @return {string}       The param value
     */

    getSearchParamValue(param) {
        const queryParams = this.$window.location.search.replace('?', '').split(/&|=/);

        for (let i = 0; i < queryParams.length; i += 2) {
            if (queryParams[i] === param) {
                return queryParams[i + 1];
            }
        }
    }

    /**
     * Set the value of a specific query param
     *
     * @param  {string} param The query param to set/update
     */
    setParamValue(param, value) {
        this.$location.search(param, value);
    }
}
