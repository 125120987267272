'use strict';

import tsFileLoaderView from './ts-file-loader.view.html';
import './ts-file-loader.style.scss';

/**
 * This directive creates an ObjectURL, from a local file.
 *
 * Attributes:
 *
 *  file-model: Model for the File ObjectURL
 *  fix-file: When true, the file goes through the fixer service (resize/rotate)
 */

class tsFileLoaderController {
    constructor($scope, $window, tsChatApi) {
        'ngInject';

        this.URL = $window.URL;
        this.$scope = $scope;
        this.chatApi = tsChatApi;
    }

    getFileURL(selectedFile) {
        if (this.fileFixer) {
            this.fileFixer
                .fix(selectedFile)
                .then((objUrl) => {
                    this.model = objUrl;

                    if (this.loadFinished) {
                        this.loadFinished({objUrl: objUrl});
                    }
                })
                .catch((err) => {
                    console.error(err);

                    if (this.loadAborted) {
                        this.loadAborted();
                    }
                });
        } else {
            this.$scope.$applyAsync(() => {
                this.model = this.URL.createObjectURL(selectedFile);

                if (this.loadFinished) {
                    this.loadFinished({objUrl: this.model});
                }
            });
        }
    }

    setListeningInput(input) {
        this.input = input;
    }

    buttonClick() {
        if (!this.chatApi.areBothSidesConnected && !this.chatApi.offlineRoom) {
            return;
        }

        if (this.loadOpen) {
            this.loadOpen();
        }

        if (this.input) {
            this.input.click();
        }
    }

    createInput(doc, scope) {
        const form = $('<form></form>')[0];
        const input = $(
            `<input id="${this.fileType}InputLoader" type="file" accept="${this.fileType}/*" ${this.captureMode === 'camera' ? 'capture="camera"' : ''} />`
        )[0];

        form.appendChild(input);

        doc.body.appendChild(form);

        this.setListeningInput(input);

        const inputChangeHandler = (event) => {
            if (event.target.files[0]) {
                if (this.loadStart) {
                    this.loadStart();
                }

                this.getFileURL(event.target.files[0]);

                // Reset the form, to support case user selects same file as before
                form.reset();
            }
        };

        input.addEventListener('change', inputChangeHandler);

        scope.$on('$destroy', () => {
            input.removeEventListener('change', inputChangeHandler);
        });

        return input;
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const iframe = element[0].querySelector('iframe');
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    let input = null;

    if (iframeDoc.readyState === 'complete') {
        input = ctrl.createInput(iframeDoc, scope);

        scope.onInputCreated({input});
    } else {
        iframe.onload = () => {
            input = ctrl.createInput(iframeDoc, scope);

            scope.onInputCreated({input});
        };
    }
}

export function tsFileLoaderDirective() {
    'ngInject';

    return {
        template: tsFileLoaderView,
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            onInputCreated: '&onInputCreated'
        },
        bindToController: {
            model: '=fileModel',
            fileFixer: '=',
            fileType: '@',
            captureMode: '@',
            loadOpen: '&',
            loadStart: '&',
            loadAborted: '&',
            loadFinished: '&'
        },
        controller: tsFileLoaderController,
        controllerAs: 'vm',
        link: linkFn
    };
}
