'use strict';

/**
 * Utility service that wraps the functionality required to re-orient and
 * resize an video
 */
export class TsPushNotificationService {
    constructor($window) {
        'ngInject';
        this.$window = $window;

        this.needPermission = true;
        this.hasPermission = false;
    }

    getPermission() {
        if (!this.needPermission) {
            return Promise.resolve();
        }

        if (!this.$window.Notification || this.$window.Notification.permission === 'denied') {
            this.hasPermission = false;
            this.needPermission = false;

            return Promise.resolve();
        }

        if (this.$window.Notification === 'granted') {
            this.hasPermission = true;
            this.needPermission = false;

            return Promise.resolve();
        }

        let requestPermission = this.$window.Notification.requestPermission();

        if (!(requestPermission instanceof Promise)) {
            requestPermission = new Promise((resolve) => this.$window.Notification.requestPermission(resolve));
        }

        return requestPermission.then((permission) => {
            if (permission === 'granted') {
                this.hasPermission = true;
                this.needPermission = false;
            } else if (permission === 'denied') {
                this.hasPermission = false;
                this.needPermission = false;
            }
        });
    }

    notify(text) {
        if (!this.hasPermission) {
            return;
        }

        const browserLocale = navigator.language.split('-')[0];
        const notificationMessage =
            browserLocale.toLowerCase() === 'fr' ? 'Message de TechSee' : 'Message from TechSee';

        this.notification = new this.$window.Notification(text, {body: notificationMessage});
    }
}
