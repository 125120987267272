'use strict';

import get from 'lodash/get';

import tsEndMeetingButtonView from './ts-end-meeting-button.view.html';
import './ts-end-meeting-button.style.scss';

const STYLES = {
    NEW: 'new',
    OLD: 'old'
};

class tsEndMeetingButtonController {
    constructor($attrs, $rootScope, $stateParams, $translate, db, tsChatApi, tsEndMeetingModalService) {
        'ngInject';

        this.chatApi = tsChatApi;
        this.$attrs = $attrs;
        this.db = db;
        this.rootScope = $rootScope;
        this.translate = $translate;
        this.isNewStyle = this.$attrs.styleButton === STYLES.NEW;
        this.endParams = $stateParams.csi ? {csi: $stateParams.csi} : {};
        this.endMeetingModalService = tsEndMeetingModalService;
        this.endMeetingModalService.init(this.isNewStyle);

        this.endMeetingButtonAria = this.translate.instant('END.VIEW.END_MEETING_BUTTON_ARIA');
    }

    get requireImage() {
        return this.rootScope.requireImage;
    }

    displayIfNew() {
        const showEndMeetingButton = get(this.chatApi, 'accountSettings.showEndMeetingButton');

        return showEndMeetingButton && this.isNewStyle;
    }
}

export function tsEndMeetingButtonDirective() {
    'ngInject';

    return {
        template: tsEndMeetingButtonView,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            styleButton: '='
        },
        controller: tsEndMeetingButtonController,
        controllerAs: 'vm'
    };
}
